import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import './style.scss';

import mairie from './../../../assets/images/mairie.jpg'


function Mairie({cityInfos}) {

    const [picture, setSelectedPicture] = useState(null);

    const handleClick = (event) => {
        const picture = event.target.src;
        setSelectedPicture(picture);
    };

  return (
      <div className="mairie">
        <div className='page__content'>
             <img className='page__content__image' src={mairie} alt='mairie de Cardet' onClick={handleClick} />

             {picture && (
                      <div className="full-screen-photo">
                        <img
                          src={picture}
                          alt='la mairie de Cardet'
                          onClick={() => setSelectedPicture(null)}
                          className="full-screen-photo__image"
                        />
                      </div>
                    )}
            <div>
                <h2>Mairie de {cityInfos.name}</h2>
                <p>L’accueil de la mairie et de l’agence postale se trouvent au rez-de-chaussée du bâtiment de la mairie.</p>
                <h4> Adresse: </h4> 
                <p>Place de la Mairie 
                <br/>30350 Cardet </p>
                <h4> Contact : </h4>
                <p>04.66.83.81.42 
                <br/>mairiedecardet@orange.fr</p>
                <h4> Horaires d’ouverture : </h4>
                <p>{cityInfos.openingHours}</p>
            </div>     
        </div>
      </div>
  );
}

Mairie.propTypes = {
};

export default Mairie;