export const FETCH_AGENDA = 'FETCH_AGENDA';
export const SAVE_AGENDA = 'SAVE_AGENDA';
export const FETCH_NEWS = 'FETCH_NEWS';
export const SAVE_NEWS = 'SAVE_NEWS';

export const fetchAgenda = () => ({
    type: FETCH_AGENDA,
});

export const saveAgenda = (agendaData) => ({
    type: SAVE_AGENDA,
    agendaData,
});

export const fetchNews = () => ({
    type: FETCH_NEWS,
});

export const saveNews = (newsData) => ({
    type: SAVE_NEWS,
    newsData,
});