export function dateConverter (date) {
  let dateArray = date.split('-');
  let year = dateArray[0];
  let month = switchMonth(dateArray[1]);
  let day = dateArray[2];
  let newDate = [ day, month, year]
  let dayOfWeek = new Date(year, dateArray[1] - 1, day).toLocaleDateString('fr-FR', { weekday: 'long' });
  newDate.unshift(dayOfWeek);
  return newDate;
};


export function dateOfFileConverter (date) {
  let dateArray = date.split('/');
  let year = dateArray[2];
  let month = switchMonth(dateArray[1]);
  let day = dateArray[0];
  if (day.charAt(0) === '0') {
    day = day.slice(1);
  }
  if (day === '1') {
    day = day + 'er';
  }
  let newDate = day + ' ' + month + ' ' + year;
  return newDate;
}

export function dateOfFileWithoutDayConverter (date) {
  let dateArray = date.split('/');
  let year = dateArray[2];
  let month = switchMonth(dateArray[1]);
  let newDate = month + ' ' + year;
  return newDate;
}


export function dayConverter (date) {
  let dateArray = date.split('-');
  let day = dateArray[2].split('T')[0];
  return day;
}


function switchMonth (month) {
  switch (month) {
    case '01':
      return 'janvier';
    case '02':
      return 'février';
    case '03':
      return 'mars';
    case '04':
      return 'avril';
    case '05':
      return 'mai';
    case '06':
      return 'juin';
    case '07':
      return 'juillet';
    case '08':
      return 'août';
    case '09':
      return 'septembre';
    case '10':
      return 'octobre';
    case '11':
      return 'novembre';
    case '12':
      return 'décembre';
    default:
      return 'error';
  };
}


export function convertDayOfWeekToAbbreviation(dayOfWeek) {
  switch (dayOfWeek.toLowerCase()) {
    case "lundi":
      return "LUN";
    case "mardi":
      return "MAR";
    case "mercredi":
      return "MER";
    case "jeudi":
      return "JEU";
    case "vendredi":
      return "VEN";
    case "samedi":
      return "SAM";
    case "dimanche":
      return "DIM";
    default:
      return "INC";
  }
}
