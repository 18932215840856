const menu = [
    {
        id: 1,
        name: 'Vie Quotidienne',
        subItems : [
            {
                id: 1,
                name: 'La mairie, les élus',
                subsubItems: [
                    {
                        id: 1,
                        name: 'Les élus & commissions',
                        path: '/vie-quotidienne/la-mairie-les-elus/les-elus-commissions',
                    },
                    {
                        id: 2,
                        name: 'Comptes rendus du conseil municipal',
                        path: '/vie-quotidienne/la-mairie-les-elus/comptes-rendus-du-conseil-municipal',
                    },
                    {
                        id: 3,
                        name: 'Appels d\'offres',
                        path: '/vie-quotidienne/la-mairie-les-elus/appels-doffres',
                    },
                ],
            },
            {
                id: 2,
                name: 'Infrastructures communales',
                subsubItems: [
                    {
                        id: 1,
                        name: 'La mairie & agence postale',
                        path: '/vie-quotidienne/infrastructures-communales/la-mairie-agence-postale',
                    },
                    {
                        id: 2,
                        name: 'La bibliothèque',
                        path: '/vie-quotidienne/infrastructures-communales/la-bibliotheque',
                    },
                    {
                        id: 3,
                        name: 'Le foyer - location',
                        path: '/vie-quotidienne/infrastructures-communales/le-foyer-location',
                    },
                    {
                        id: 4,
                        name: 'Equipements sportifs & loisirs',
                        path: '/vie-quotidienne/infrastructures-communales/equipements-sportifs-loisirs',
                    }
                ],
            },
            {
                id: 3,
                name: 'Les associations',
                path: '/vie-quotidienne/les-associations',
            },
            {
                id: 4,
                name: 'Commerces & entreprises',
                path: '/vie-quotidienne/commerces-entreprises-restaurants',
            },
            {
                id: 5,
                name: 'Intercommunalité',
                subsubItems: [
                    {
                        id: 1,
                        name: 'Informations & Actualités',
                        path: 'https://piemont-cevenol.fr/',
                        target: '_blank',
                    },
                    {
                        id: 2,
                        name: 'Centre de loisirs',
                        path: 'https://piemont-cevenol.fr/accueils-de-loisirs/',
                        target: '_blank',
                    },
                    {
                        id: 3,
                        name: 'Collecte des déchets',
                        path: 'https://piemont-cevenol.fr/la-collecte-des-dechets-menagers/',
                        target: '_blank',
                    },
                    {
                        id: 4,
                        name: 'Comptes-rendus du conseil communautaire',
                        path: 'https://piemont-cevenol.fr/le-conseil-communautaire/comptes-rendus-des-conseils-communautaires/',
                        target: '_blank',
                    },
                    {   
                        id: 5,
                        name: 'Crèche',
                        path: 'https://piemont-cevenol.fr/creche-de-ledignan/',
                        target: '_blank',
                    },
                ],
            },
            {
                id: 6,
                name: 'Citoyenneté',
                subsubItems: [
                    {
                        id: 1,
                        name: 'Cafés citoyens',
                        path: '/vie-quotidienne/citoyennete/cafes-citoyens',
                    },
                    {
                        id: 2,
                        name: 'Bien vivre ensemble',
                        path: '/vie-quotidienne/citoyennete/bien-vivre-ensemble',
                    },
                    {
                        id: 3,
                        name: 'Voisins vigilants',
                        path: '/vie-quotidienne/citoyennete/voisins-vigilants',
                    },
                ]
            },
            {
                id: 7,
                name: 'Enfance & Jeunesse',
                subsubItems: [
                    {
                        id: 1,
                        name: 'Ecole',
                        path: '/vie-quotidienne/enfance-jeunesse/ecole',
                    },
                    {
                        id: 2,
                        name: 'Transport scolaire',
                        path: '/vie-quotidienne/enfance-jeunesse/transport-scolaire',
                    },
                    {
                        id: 3,
                        name: 'Les nounous',
                        path: '/vie-quotidienne/enfance-jeunesse/les-nounous',
                    },
                ],
            },
            {
                id: 8,
                name: 'Gestion des déchets',
                path: '/vie-quotidienne/gestion-des-dechets',
            },
            {
                id: 9,
                name: 'Numéros utiles',
                path: '/vie-quotidienne/numeros-utiles',
            },
            {
                id: 10,
                name: 'Bulletin municipal "Le Cardésien"',
                path: '/vie-quotidienne/bulletin-municipal-le-cardesien',
            },
        ]
    },
    
    {
        id: 2,
        name: 'Territoire',
        subItems: [
            {
                id: 1,
                name: 'Plan local d\'urbanisme',
                subsubItems: [
                    {
                        id: 1,
                        name: 'Procédure PLU',
                        path: '/territoire/plan-local-durbanisme/procedure',
                    },
                    {
                        id: 2,
                        name: 'Rapport de présentation',
                        path: '/territoire/plan-local-durbanisme/rapport-de-presentation', 
                    },
                    {
                        id: 3,
                        name: 'PADD',
                        path: '/territoire/plan-local-durbanisme/padd',
                    },
                    {
                        id: 4,
                        name: 'Règlement',
                        path: '/territoire/plan-local-durbanisme/reglement',
                    },
                    {
                        id: 5,
                        name: 'Annexes',
                        path: '/territoire/plan-local-durbanisme/annexes',
                    },
                    {
                        id: 6,
                        name: 'Orientations d\'aménagement',
                        path: '/territoire/plan-local-durbanisme/orientations-damenagement',
                    },
                ],
            },
            {
                id: 2,
                name: 'Prévention des risques',
                subsubItems: [
                    {
                        id: 1,
                        name: 'Les bruits',
                        path: '/territoire/prevention-des-risques/les-bruits',
                    },
                    {
                        id: 2,
                        name: 'Les animaux',
                        path: '/territoire/prevention-des-risques/les-animaux',
                    },
                ],
            },
            {
                id: 3,
                name: 'Eau & Assainissement',
                path: '/territoire/eau-assainissement',
            },
            {
                id: 4,
                name: 'Patrimoine',
                path: '/territoire/patrimoine',
            },
            {
                id: 5,
                name: 'Les artistes',
                path: '/territoire/les-artistes',
            },
            {
                id: 6,
                name: 'Un peu d\'histoire',
                path: '/territoire/un-peu-dhistoire',
            },
        ],
                
    },

    {
        id: 3,
        name: 'Tourisme',
        subItems: [
            {
                id: 1,
                name: 'Gîtes & Chambres d\'hôtes',
                path: '/tourisme/gites-chambres-dhotes',
            },
            {
                id: 2,
                name: 'Campings',
                path: '/tourisme/campings',
            },
            {
                id: 3,
                name: 'Office Cévennes Tourisme',
                path: 'https://www.cevennes-tourisme.fr/',
                target: '_blank',
            },
            {
                id: 4,
                name: 'Office de tourisme du Piémont Cévenol',
                path: 'https://piemont-cevenol-tourisme.com/',
                target: '_blank',
            },
        ],
    }
];

export function findSubItemsByItemId(id) {
    return menu.find(item => item.id === Number(id)).subItems;
}

export default menu;