import axios from 'axios';

import {
    FETCH_WEATHER,
    saveWeather,
  } from '../actions/weather';
  
  
  const weathermiddleware = (store) => (next) => (action) => {
    switch (action.type) {
      case FETCH_WEATHER: {
        axios.get(
            `https://api.openweathermap.org/data/2.5/forecast?lat=44.0263&lon=4.08033&appid=${process.env.REACT_APP_WEATHER_API_KEY}&lang=fr&units=metric`
        )
          .then((response) => {
            store.dispatch(saveWeather(response.data));
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }
  
  
      default:
        next(action);
    }
  };
  
  export default weathermiddleware;