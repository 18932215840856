import philippePinchard from './../assets/images/conseilMunicipal/philippe_pinchard.jpg';
import sophiePoujol from './../assets/images/conseilMunicipal/sophie_poujol.jpg';
import fabienCruveiller from './../assets/images/conseilMunicipal/fabien_cruveiller.jpg';
import stephaneBrioni from './../assets/images/conseilMunicipal/stephane_brioni.jpg';
import laurentRoque from './../assets/images/conseilMunicipal/laurent_roque.jpg';
import aubeAllemand from './../assets/images/conseilMunicipal/aube_allemand.jpg';
import jeremyBrito from './../assets/images/conseilMunicipal/jeremy_brito.jpg';
import didierDurand from './../assets/images/conseilMunicipal/didier_durand.jpg';
import pierreDurandet from './../assets/images/conseilMunicipal/pierre_durandet.jpg';
import laetitiaFoury from './../assets/images/conseilMunicipal/laetitia_foury.jpg';
import nicolasRome from './../assets/images/conseilMunicipal/nicolas_rome.jpg';
import sylviaVeryha from './../assets/images/conseilMunicipal/sylvia_veryha.jpg';


const commissions = [
    {
        id: 1,
        name: "PCS – PPRI (Volet opérationnel)",
    },
    {
        id: 2,
        name: "Cérémonies Municipales et Vie Associative",
    },
    {
        id: 3,
        name: "Personnel Communal",
    },
    {
        id: 5,
        name: "Affaires Scolaires",
    },
    {
        id: 6,
        name: "Piémont Cévenol",
    },
    {
        id: 7,
        name: "CCAS",
    },
    {
        id: 8,
        name: "Voirie - Bâtiments et Equipements sportifs",
    },
    {
        id: 9,
        name: "Vie Locale – Evénements Culturels et Manifestations",
    },
    {
        id: 10,
        name: "Finances - EAU Assainissement",
    },
    {
        id: 11,
        name: "Communication",
    },
    {
        id: 12,
        name: "PCS – PPRI",
    },
    {
        id: 13,
        name: "Urbanisme - PPRI (Volet Urba) - Environnement - Cadre De Vie - Transition Énergétique",
    },
    {
        id: 14,
        name: "Piémont Cévenol « Tourisme »",
    },
    {
        id: 15,
        name: "Piémont Cévenol « Env et TE »",
    },
    {
        id: 17,
        name: "« Sport »",
    },
    {
        id: 18,
        name: "« ADE – DEV éco – PST »",
    },
    {
        id: 19,
        name: "Piémont Cévenol « Culture »",
    },

];

export function findCommissionName(id) {
    const commission = commissions.find((commission) => commission.id === id);
    return commission.name;
};

const conseilMunicipalData = [
    {
        id: 1,
        name: "Philippe Pinchard",
        role: "1er Adjoint",
        delegation: "Délégation Voirie",
        responsabiliteIds: [8],
        commissionsIds: [1, 2, 3],
        picture: philippePinchard,
    },
    {
        id: 2,
        name: "Sophie Poujol",
        role: "2ème Adjointe",
        delegation: "Délégation Finances",
        responsabiliteIds: [10],
        commissionsIds: [1, 3, 2, 5],
        picture: sophiePoujol,
    },
    {
        id: 3,
        name: "Fabien Cruveiller",
        role: "Maire",
        delegation: "Administration générale",
        responsabiliteIds: [3, 6, 5, 12, 7, 2],
        commissionsIds: null,
        picture: fabienCruveiller,
    },
    {
        id: 4,
        name: "Stéphane Brioni",
        role: "3ème Adjoint",
        delegation: "Délégation Urbanisme",
        responsabiliteIds: [13],
        commissionsIds: [1, 8, 3, 10, 2],
        picture: stephaneBrioni,
    },
    {
        id: 5,
        name: "Laurent Roque",
        role: "4ème Adjoint",
        delegation: "Délégation Culture et Communication",
        responsabiliteIds: [9, 11],
        commissionsIds: [1, 8, 6, 17, 3, 7],
        picture: laurentRoque,
    },
    {
        id: 6,
        name: "Aube Allemand",
        role: "Conseillère municipale",
        commissionsIds: [6, 18],
        picture: aubeAllemand,
        autre: "Déléguée Communautaire"
    },
    {
        id: 8,
        name: "Jérémy Brito",
        role: "Conseiller municipal",
        commissionsIds: [8, 13, 9 ],
        picture: jeremyBrito,
    },
    {
        id: 9,
        name: "Didier Durand",
        role: "Conseiller municipal",
        commissionsIds: [8, 13, 2, 3 ],
        picture: didierDurand,
    },
    {
        id: 10,
        name: "Pierre Durandet",
        role: "Conseiller municipal",
        commissionsIds: [8, 13, 2 ],
        picture: pierreDurandet,
    },
    {
        id: 11,
        name: "Laetitia Foury",
        role: "Conseillère municipale",
        commissionsIds: [2, 13, 11],
        picture: laetitiaFoury,
    },
    {
        id: 13,
        name: "Nicolas Rome",
        role: "Conseiller municipal",
        commissionsIds: [5, 13, 9 ],
        picture: nicolasRome,
    },
    {
        id: 15,
        name: "Sylvia Veryha",
        role: "Conseillère municipale",
        commissionsIds: [2, 3, 7 ],
        picture: sylviaVeryha,
    },
];

export default conseilMunicipalData;

