import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
// import PropTypes from 'prop-types';

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './../../containers/Home';
import Error from './../../components/Error';
import Page from './../../containers/Page';
import Administration from './../../containers/Administration';

import './style.scss';
//import AddFile from '../../containers/AddFile';

function App({
  fetchAgenda,
  loadWeatherData,
  fetchNews,
  getFiles,
  getCityInfos,
}) {

  useEffect(() => {
    loadWeatherData();
    fetchNews();
    fetchAgenda();
    getFiles();
    getCityInfos();
  }, [loadWeatherData, fetchNews, fetchAgenda, getFiles, getCityInfos]);

  return (
    <div className="app">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/administration' element={<Administration />} />
        <Route path='/administration/*' element={<Administration />} />
        <Route path='/vie-quotidienne/*' element={<Page />} />
        <Route path='/territoire/*' element={<Page />} />
        <Route path='/tourisme/*' element={<Page />} />
        <Route path='/actualite' element={<Page />} />
        <Route path='/agenda' element={<Page />} />

        {/* Route pour les routes non correspondantes */}
        <Route path='/*' element={<Error />} />
      </Routes>
    </div>
  );
}

export default App;
