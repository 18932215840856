import { connect } from 'react-redux';
import Page from './../../components/Page';

import { findPage } from './../../selectors/pages';
import pages from './../../data/pages';
import { getFiles } from './../../store/actions/file';


const mapStateToProps = (state, ownProps) => ({
  page: findPage(pages),
  newsData: state.intraMuros.newsData,
  agendaData: state.intraMuros.agendaData,
  filesByCategory: state.file.filesByCategory,
  cityInfos: state.city.infos,
});

const mapDispatchToProps = (dispatch) => ({
  getFiles: () => {
    dispatch(getFiles());
}
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);

