import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import Card from '../../MyCard';
import './style.scss';
import moment from 'moment';

moment.updateLocale('fr', {
  months : [
      "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
});


function Calendar({cards}) {

  cards.sort((a, b) => {
    return new Date(a.start_date) - new Date(b.start_date);
  });

  const [currentMonth, setCurrentMonth] = useState(moment().startOf('month').subtract(1, 'month +1'));

  const currentMonthEvents = cards.filter(event => moment(event.start_date).isSame(currentMonth, 'month'));
  const nextMonthEvents = cards.filter(event => moment(event.start_date).isSame(currentMonth.clone().add(1, 'month'), 'month'));

  let isMobile = false;
  if (window.innerWidth < 768) {
    isMobile = true;
  }

  const showPreviousMonthButton = moment().startOf('month').isBefore(currentMonth, 'month');

  return (
    <div className='calendar'>
      <div className='calendar__row1'>
        <div className='calendar__row1__month'>
          <p className='calendar__row1__month__title'> {currentMonth.format('MMMM YYYY')} </p>
          {currentMonthEvents.map((article) => (
            <Card article={article} key={article.id} allAgendaItems/>
          ))}
        </div>
        {!isMobile &&
        <div className='calendar__row1__month'>
          <p className='calendar__row1__month__title'> {currentMonth.clone().add(1, 'month').format('MMMM YYYY')} </p>
          {nextMonthEvents.map((article) => (
            <Card article={article} key={article.id} allAgendaItems/>
          ))}
        </div>
        }
      </div>
      <div className='calendar__row2'>
        <div>
        {showPreviousMonthButton &&
          <div onClick={() => setCurrentMonth(currentMonth.clone().add(-1, 'month'))}> <i className="bi bi-chevron-double-left calendar__row2__button"></i> </div>
        }
        </div>
        <div>
          <div onClick={() => setCurrentMonth(currentMonth.clone().add(1, 'month'))}> <i className="bi bi-chevron-double-right calendar__row2__button"></i> </div>
        </div>
      </div>
    </div>
  );
}

Calendar.propTypes = {
};

export default Calendar;
