export function makeDailySummary(weatherData) {

  const days = {};

  if(!weatherData) return [];

  for (let i = 0; i < weatherData.length; i++) {
    const timestamp = weatherData[i].dt;
    const date = new Date(timestamp * 1000);
    const day = date.toDateString();

    if (!days[day]) {
      days[day] = { maxTemp: Number.NEGATIVE_INFINITY };
      days[day].minTemp = Number.POSITIVE_INFINITY;
    }

    if (weatherData[i].main.temp_max > days[day].maxTemp) {
      days[day].maxTemp = Math.round(weatherData[i].main.temp_max);
      days[day].weather = weatherData[i].weather[0].description;
      days[day].icon = weatherData[i].weather[0].icon;
    }

    if (weatherData[i].main.temp_min < days[day].minTemp) {
      days[day].minTemp = Math.round(weatherData[i].main.temp_min);
    }
  }

  return Object.keys(days).map((day) => {
    return {
      day,
      maxTemp: days[day].maxTemp,
      minTemp: days[day].minTemp,
      weather: days[day].weather,
      icon: days[day].icon,
    };
  });
}
