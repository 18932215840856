export const associationsCategories = [
    {
        id: 1,
        name: "Associations sportives",
    },
    {
        id: 2,
        name: "Associations du vivre ensemble",
    },
    {
        id: 3,
        name: "Associations culturelles",
    }
]


export function getAllAssociationsofCategory(categoryId) {
    return associations.filter((association) => association.categorie === categoryId);
}

const associations = [
    {
        id: 1,
        name: "OLYMPIQUE DE CARDET",
        description: "football",
        president: "Mr Fabrice VIALA",
        tel: "06 63 22 29 44",
        categorie: 1,
    },
    {
        id: 2,
        name: "GYM +",
        description: "Gymnastique",
        president: "Mr Etienne VILLARET",
        tel: "06 13 24 24 08",
        mail: "clubgymplus@wanadoo.fr",
        categorie: 1,
    },
    {
        id: 3,
        name: "LA BOULE BEAU RIVAGE",
        description: "Pétanque",
        president: "Mr Jean-Luc GOMEZ",
        tel: "06 14 83 77 68",
        categorie: 1, 
    },
    {
        id: 4,
        name: "LE VCC (VARIETE CLUB DE CARDET)",
        description: "Foot détente",
        president: "Mr Nicolas DUMAS",
        tel: "06 22 35 70 73",
        categorie: 1,
    },
    {
        id: 5,
        name: "MISAELLE YOGA",
        description: "Yoga",
        president: "Mme Marie MISAELLE",
        tel: "06 29 30 06 88",
        categorie: 1,
    },
    {
        id: 6,
        name: "THOR COACHING",
        description: "Circuit Training",
        president: "Mr Manuel DONZALA",
        tel: "06 14 53 36 17",
        categorie: 1,
    },
    {
        id: 7,
        name: "APE ENSEMBLE POUR LES ENFANTS",
        description: "Association des parents d'élèves",
        president: "Mr Aurélien RUIZ",
        tel: "06 76 56 56 61",
        categorie: 2,
    },
    {
        id: 8,
        name: "CERCLE GENEALOGIQUE GARD LOZERE",
        description: "Généalogie",
        president: "Mme Françoise REDON",
        tel: "04 66 75 29 29",
        link: "https://www.cggl.fr/",
        target: "_blank",
        categorie: 2,
    },
    {
        id: 9,
        name: "LE CLUB LES AMIS DE L’AGE D’OR",
        description: "Club des aînés",
        president: "Mme Renée BLASQUEZ",
        tel: "04 66 83 07 98",
        categorie: 2,
    },
    {
        id: 10,
        name: "LE COMITE DES FETES DE CARDET",
        description: "Fêtes",
        president: "Mr Pierrick FOURNEL",
        tel: "06 33 80 65 80",
        categorie: 2,
    },
    {
        id: 11,
        name: "SOCIETE DE CHASSE",
        description: "Chasse",
        president: "Mr Christophe GAILLARD",
        tel: "06 99 88 22 58",
        categorie: 2,
    },
    {
        id: 12,
        name: "LE CLUB TAURIN CARDESIEN",
        description: "Club taurin",
        president: "Mr Frédéric BAZALGETTE",
        tel: "06 52 89 50 21",
        categorie: 2,
    },
    {
        id: 13,
        name: "LES MAMZ’ELLES",
        description: "Maison d’assistantes maternelles",
        link: "/vie-quotidienne/enfance-jeunesse/les-nounous",
        target: "_self",
        categorie: 2,
    },
    {
        id: 14,
        name: "JMB MUSIQUE",
        description: "Chansons, musique",
        president: "Mme Sabine TRUNNET",
        tel: "07 61 07 91 76",
        categorie: 3,
    },
    {
        id: 15,
        name: "JPS En Chantant",
        description: "Musique et Chansons de variétés françaises",
        president: "Mr Norbert BOUSCHET",
        tel: "06 82 96 60 91",
        categorie: 3,
    },
    {
        id: 16,
        name: "LES MOUETTES RIEUSES",
        description: "Théâtre",
        president: "Mme Véronique LECHARTIER",
        tel: "06 95 76 83 39",
        categorie: 3,
    }
]

