import { connect } from 'react-redux';
import App from './../../components/App';

import { fetchWeather } from './../../store/actions/weather';
import { fetchAgenda, fetchNews } from './../../store/actions/intraMuros';
import { getFiles } from './../../store/actions/file';
import { getCityInfos } from '../../store/actions/city';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    loadWeatherData: () => {
        dispatch(fetchWeather());
    },

    fetchAgenda: () => {
        dispatch(fetchAgenda());
    },

    fetchNews: () => {
        dispatch(fetchNews());
    },

    getFiles: () => {
        dispatch(getFiles());
    },

    getCityInfos: () => {
        dispatch(getCityInfos());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);