import React, {useState} from 'react';
// import PropTypes from 'prop-types';

import './style.scss';

function CityInfo({
    cityInfos,
    updateCityInfos 
}) {
    
    const [residents, setResidents] = useState(cityInfos.numberOfResidents);
    const [openingHours, setOpeningHours] = useState(cityInfos.openingHours);
    const [mayor, setMayor] = useState(cityInfos.nameOfMayor);
    
    const [isEditing, setIsEditing] = useState(false);


    const toggleEdit = (evt) => {
        setIsEditing(evt.target.id);
    }

    const changeResidents = (event) => {
        setResidents(event.target.value);
    }

    const changeMayor = (event) => {
        setMayor(event.target.value);
    }

    const changeOpeningHours = (event) => {
        setOpeningHours(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let newCityInfos = {
            residents,
            openingHours,
            mayor
        };
        updateCityInfos(newCityInfos);
        setTimeout(() => {
            setIsEditing(false);
        }
        , 500);
    }   

    return (
        <div className='cityInfo'>
            <p> Nom : {cityInfos.name} </p>
            
            {isEditing === "residents" ?
                <form onSubmit={handleSubmit}>
                    <label htmlFor="residents">Nombre d'habitants</label>
                    <input id="residents" type="text" placeholder="Nombre d'habitants" value={residents} onChange={changeResidents}/>
                    <button type="submit" id="residents">Valider</button>
                    <button onClick={toggleEdit} >Annuler</button>
                </form>
                : 
                <div>
                    <span> Nombre d'habitants : {cityInfos.numberOfResidents} </span>
                    <button onClick={toggleEdit} className='ms-2' id="residents" > Modifier </button>
                </div>
            }



            <p> Superficie : {cityInfos.surfaceArea} km2 </p>

            {isEditing === "mayor" ?
                <form onSubmit={handleSubmit}>
                    <label htmlFor="mayor">Maire</label>
                    <input id="mayor" type="text" placeholder="Nom du maire" value={mayor} onChange={changeMayor}/>
                    <button type="submit" id="mayor" >Valider</button>
                    <button onClick={toggleEdit}>Annuler</button>
                </form>
                : 
                <div>
                    <span> Maire : {cityInfos.nameOfMayor} </span>
                    <button onClick={toggleEdit} className='ms-2' id="mayor"> Modifier </button>
                </div>
            }

            

            {isEditing === "openingHours" ?
                <form onSubmit={handleSubmit}>
                    <label htmlFor="openingHours">Nombre d'habitants</label>
                    <textarea id="openingHours" type="text" placeholder="Horaires d'ouverture" value={openingHours} onChange={changeOpeningHours}/>
                    <button type="submit" id="openingHours" >Valider</button>
                    <button onClick={toggleEdit}>Annuler</button>
                </form>
                : 
                <div>
                    <span> Horaires d'ouverture : {cityInfos.openingHours} </span>
                    <button onClick={toggleEdit} className='ms-2' id="openingHours"> Modifier </button>
                </div>
            }
        </div>
    );
};

export default CityInfo;
