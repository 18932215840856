import React, { useContext, useRef, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

export default function LoginModal() {
  const { modalState, toggleModals, login, resetPassword } = useContext(UserContext);

  const navigate = useNavigate();

  const [validation, setValidation] = useState("");

  const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };
  const formRef = useRef();

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await login(
        inputs.current[0].value,
        inputs.current[1].value
      );
      setValidation("");
      toggleModals("close");
      navigate("/administration");
    } catch {
      setValidation("Wopsy, identifiants incorrects")
    }
  };

  const closeModal = () => {
    setValidation("");
    toggleModals("close");
  };

  const forgottenPassword = (e) => {
    e.preventDefault();
  const email = inputs.current[0].value;

  if (email) {
    // Expression régulière pour valider le format de l'e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      try {
        resetPassword(email);
        setValidation("Si votre adresse e-mail est connue, vous allez recevoir un e-mail de réinitialisation de mot de passe");
      } catch {
        setValidation("Wopsy, une erreur est survenue");
      }
    } else {
      setValidation("Veuillez saisir une adresse e-mail valide");
    }
  } else {
    setValidation("Veuillez renseigner votre email");
  }
};

  return (
    <>
      {modalState.signInModal && (
        <div className="position-fixed top-0 vw-100 vh-100">
          <div
            onClick={closeModal}
            className="w-100 h-100 bg-dark bg-opacity-75"
          ></div>
          <div
            className="position-absolute top-50 start-50 translate-middle bg-light rounded-3 p-4"
            style={{ minWidth: "400px" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Se connecter</h5>
                  <button onClick={closeModal} className="btn-close"></button>
                </div>

                <div className="modal-body">
                  <form
                    ref={formRef}
                    onSubmit={handleForm}
                    className="sign-up-form"
                  >
                    <div className="mb-3">
                      <label htmlFor="signInEmail" className="form-label">
                        Email
                      </label>
                      <input
                        ref={addInputs}
                        name="email"
                        required
                        type="email"
                        className="form-control"
                        id="signInEmail"
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="signInPwd" className="form-label">
                        Mot de passe
                      </label>
                      <input
                        ref={addInputs}
                        name="pwd"
                        required
                        type="password"
                        className="form-control"
                        id="signInPwd"
                      />
                      <p className="text-danger mt-1">{validation}</p>
                    </div>
                    <button className="btn btn-link" onClick={forgottenPassword}>Mot de passe oublié ?</button>
                    <button className="btn btn-primary ms-3">Se connecter</button>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}