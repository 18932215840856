import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import MyModal from './../MyModal';
import './style.scss';

import { dayConverter } from './../../../selectors/date';


function AllAgendaItems({article}) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleReadClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='allAgendaItems' >
        <div className='allAgendaItems__day'> { dayConverter(article.start_date) } </div>
        <img src={article.image} alt={article.title} className='allAgendaItems__image'/>
        <div>
          <h2 className='allAgendaItems__title'>{article.title}</h2>
          <p dangerouslySetInnerHTML={{__html: article.description.slice(0, 60)}}></p>
          <p className='allAgendaItems__place'>{article.event_place}</p>
          <button className='allAgendaItems__button' onClick={handleReadClick}>En savoir +</button>
          <MyModal article={article} handleCloseModal={handleCloseModal} isModalOpen={isModalOpen} />
        </div>
        
    </div>
  ); 
}

AllAgendaItems.propTypes = {
};

export default AllAgendaItems;