import axios from 'axios';

import {
    FETCH_AGENDA,
    saveAgenda,
    FETCH_NEWS,
    saveNews,
  } from '../actions/intraMuros';
  
  
  const intraMurosmiddleware = (store) => (next) => (action) => {
    switch (action.type) {
      case FETCH_AGENDA: {
        axios.get(
            `https://api.appli-intramuros.com/events/?city-id=5657&agglo-id=632`
        )
          .then((response) => {
            store.dispatch(saveAgenda(response.data));
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }

      case FETCH_NEWS: {
        axios.get(
            `https://api.appli-intramuros.com/news/?city-id=5657&agglo-id=632&actors-id=182210`
        )
          .then((response) => {
            store.dispatch(saveNews(response.data));
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }
  
  
      default:
        next(action);
    }
  };
  
  export default intraMurosmiddleware;