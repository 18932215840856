import { 
    GET_FILES, 
    saveFiles,
    UPLOAD_FILE,
    GET_CATEGORIES,
    saveCategories,
    setIsSuccess,
    EDIT_FILE,
    DELETE_FILE,
    getFiles,
  } from '../actions/file';
import api from './utils/api';
  
const fileMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        
      case GET_FILES: {
          api({
          method: 'GET',
          url: `files`,
          })
          .then((response) => {
            store.dispatch(saveFiles(response.data));
          })
          .catch((error) => {
              console.log(error)
              });
          break;
      }
     
    case UPLOAD_FILE: {
        api({
          method: 'POST',
          url: `files/upload`,
          data: action.formData,
          timeout: 0,
        })
          .then((response) => {
            store.dispatch(setIsSuccess(true));
            store.dispatch(getFiles());
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }

    case GET_CATEGORIES: {
        api({
          method: 'GET',
          url: `categories`,
        })
          .then((response) => {
            store.dispatch(saveCategories(response.data));
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }

    case EDIT_FILE: {
        api({
          method: 'PATCH',
          url: `files/${action.id}`,
          data: action.editedFile,
        })
          .then((response) => {
            store.dispatch(getFiles());
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }

    case DELETE_FILE: {
        api({
          method: 'DELETE',
          url: `files/${action.id}`,
          data: { publicId: action.publicId },
        })
          .then((response) => {
            store.dispatch(getFiles());
          })
          .catch((error) => {
              console.log(error)
            });
        break;
      }

      default:
    next(action);

    }
};
  
export default fileMiddleware;
  