export function sortFilesByYear(files) {
  // create an object to store files by year
  const filesPerYear = {};

  function extractYearFromDate(dateString) {
    const [, ,year] = dateString.split('/');
    return parseInt(year, 10);
  }

  // map through files and add them to the object
  files.forEach((file) => {
    // extract the year from the file date
    const year = extractYearFromDate(file.date);
  
      // If the year doesn't exist in the object, create it
      if (!filesPerYear[year]) {
        filesPerYear[year] = [];
      }
  
      // Add the file to the array of the year
      filesPerYear[year].push(file);
    });

    // Sort the years in descending order
    const sortedYears = Object.keys(filesPerYear).sort((a, b) => b - a);

    // Sort the files in descending order
    sortedYears.forEach((year) => {
      filesPerYear[year].sort((a, b) => {
        const dateA = convertToDate(a.date);
        const dateB = convertToDate(b.date);
        return dateB - dateA;
      });
    });

    // Function to convert date string to Date object
    function convertToDate(dateString) {
      const [day, month, year] = dateString.split('/');
      return new Date(`${year}-${month}-${day}`);
    }
    
    const reversedEntriesInArray = Object.entries(filesPerYear).reverse();
  
    return reversedEntriesInArray;
  }
  