import React from 'react';
import Header from '../Header';



const Error = () => (
  <>
    <Header/>
    <p>Nous sommes désolés, une erreur s'est produite.</p>
  </>
);

export default Error;