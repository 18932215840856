import React from 'react';
//import PropTypes from 'prop-types';
import './style.scss';


function List({categories, data, isAssociation}) {

  return (
      <div className="list">
       { categories.map((category) =>
        <div key={category.id} className="list__category">
          <h2 className="list__category__title">{category.name}</h2>
          <div>
            { data(category.id).map((element) =>
              <div key={element.id} className="list__category__element">
                <p className="list__category__element__name"> {element.name} </p>
                { element.description && <p className="list__category__element__description"> {element.description}  </p> }
                { element.president && <p className="list__category__element__contact">{isAssociation ? <b>Président</b> : <b> Responsable </b> } : {element.president}</p> }
                { element.address && <p className="list__category__element__contact"><b>Adresse</b> : {element.address}</p> }
                { element.tel && <p className="list__category__element__contact"><b>Tél</b> : {element.tel}</p> }
                { element.mail && <p className="list__category__element__contact"><b>E-mail</b> : {element.mail}</p> }
                { element.link && <a className="list__category__element__link" href={element.link} target={element.target}> <i className="bi bi-box-arrow-up-right me-2"></i> Site internet </a> }
              </div>
            )}
          </div>
        </div>
        )}
      </div>
  );
}

List.propTypes = {
};

export default List;