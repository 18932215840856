import React, { useState } from 'react';
import menuItems, { findSubItemsByItemId } from '../../../data/menu.js';
import './style.scss';

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSubItems, setActiveSubItems] = useState({});
  const [activeSubSubItems, setActiveSubSubItems] = useState({});

  const handleBurgerMenuButton = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItem = (itemId) => {
    setActiveSubItems((prevState) => ({
      [itemId]: !prevState[itemId],
    }));
  };

  const handleSubMenuItem = (subItemId) => {
    setActiveSubSubItems((prevState) => ({
      [subItemId]: !prevState[subItemId],
    }));
  };

  return (
    <div className="burgerMenu">
      <button onClick={handleBurgerMenuButton} className="burgerMenu__button">
        <i className="bi bi-list"></i>
      </button>

      {isOpen && (
        <div className="burgerMenu__container">
          <div className="burgerMenu__container__close">
            <i
              className="bi bi-x burgerMenu__container__close__icon"
              onClick={handleBurgerMenuButton}
            ></i>
          </div>
          {menuItems.map((item) => (
            <div key={item.id}>
              <div key={item.id} className="burgerMenu__container__item" onClick={() => handleMenuItem(item.id)} >
                <p className="burgerMenu__container__item__link">{item.name}</p>
                { activeSubItems[item.id] ? <i className={`bi bi-caret-down-fill burgerMenu__container__item__icon`}></i> : <i className={`bi bi-caret-right-fill burgerMenu__container__item__icon`}></i> }
              </div>

              {activeSubItems[item.id] && (
                <div className="burgerMenu__container__submenu">
                  {findSubItemsByItemId(item.id).map((subItem) => (
                    <div key={subItem.id}>
                      {subItem.subsubItems ? (
                        <div key={subItem.id}>
                          <div key={subItem.id} className="burgerMenu__container__submenu__subitem" onClick={() => handleSubMenuItem(subItem.id)}>
                            <p className="burgerMenu__container__submenu__subitem__link"> {subItem.name} </p>
                            { activeSubSubItems[subItem.id] ? <i className={`bi bi-caret-down-fill burgerMenu__container__submenu__subitem__icon`}></i> : <i className={`bi bi-caret-right-fill burgerMenu__container__submenu__subitem__icon`}></i> }
                          </div>
                          {activeSubSubItems[subItem.id] && (
                            <div className="">
                              {subItem.subsubItems.map((subsubItem) => (
                                <div key={subsubItem.id} className="burgerMenu__container__subsubmenu__subsubitem" >
                                  <p className="burgerMenu__container__subsubmenu__subsubitem__link">
                                    <a href={subsubItem.path} className="burgerMenu__container__subsubmenu__subsubitem__link__text" target={subsubItem.target ? subsubItem.target : '_self'}> {subsubItem.target && <i className="bi bi-box-arrow-up-right me-2"></i>} {subsubItem.name} </a>
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div key={subItem.id} className="burgerMenu__container__submenu__subitem" >
                          <p className="burgerMenu__container__submenu__subitem__link">  
                            <a href={subItem.path} className="burgerMenu__container__submenu__subitem__link__text" target={subItem.target ? subItem.target : '_self'} > {subItem.target && <i className="bi bi-box-arrow-up-right me-2"></i>} {subItem.name} </a> 
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BurgerMenu;


