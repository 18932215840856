import React from 'react';
//import PropTypes from 'prop-types';
import './style.scss';

const elements = [
  {
    id: 1,
    icon: 'bi bi-palette',
    title: 'Cantine & Garderie',
    link: 'https://www.espace-citoyens.net/mairiedecardet/espace-citoyens/',
    target: '_blank',
  },
  {
    id: 2,
    icon: 'bi bi-trash',
    title: 'déchets',
    link: `/vie-quotidienne/gestion-des-dechets`,
  },
  {
    id: 3,
    icon: 'bi bi-telephone',
    title: 'Annuaire',
    link: `/vie-quotidienne/numeros-utiles`,
  },
  {
    id: 4,
    icon: 'bi bi-people',
    title: 'Associations',
    link: `/vie-quotidienne/les-associations`,
  },
  {
    id: 5,
    icon: 'bi bi-cup-straw',
    title: 'Foyer communal',
    link: `/vie-quotidienne/infrastructures-communales/le-foyer-location`,
  },
  {
    id: 6,
    icon: 'bi bi-newspaper',
    title: `Le Cardésien`,
    link: `/vie-quotidienne/bulletin-municipal-le-cardesien`,
  },
  {
    id: 7,
    icon: 'bi bi-files',
    title: 'Comptes rendus du conseil municipal',
    link: `/vie-quotidienne/la-mairie-les-elus/comptes-rendus-du-conseil-municipal`,
  },

]

function Services() {

  return (
      <div className="services">
        <h2 className='homeSectionTitle servicesTitle'> Accès <b>rapides</b></h2>
        {elements.map((element) => (
          <div className="services__element" key={element.id}>
            <i className={element.icon + ' services__element__icon'}> </i> 
            {element.target && <i className="bi bi-box-arrow-up-right services__element__icon__ext "></i>}
            <a href={element.link} className='services__element__link' target={element.target ? element.target : '_self'}> {element.title.toUpperCase()}</a>
          </div>
        ))}
      </div>
  );
}

Services.propTypes = {
};

export default Services;