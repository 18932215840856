import React from 'react';
//import PropTypes from 'prop-types';
import Card from '../../MyCard';
import './style.scss';


function CardsContainer({cards, allNewsItems, allAgendaItems}) {

  cards.sort((a, b) => {
    return new Date(b.published_at) - new Date(a.published_at);
  });

  if (allAgendaItems) {
    cards.sort((a, b) => {
      return new Date(a.start_date) - new Date(b.start_date);
    });
  }
  

  let isMobile = false;
  if (window.innerWidth < 768) {
    isMobile = true;
  }
  
  return (
      <div className="cardsContainer">
        {isMobile ? (
            cards.map((article) => (
              <Card article={article} key={article.id} allNewsItems articles={cards}/>
            )) 
        ) : (
          <>
          { 
            allNewsItems && (
            cards.map((article) => (
              <Card article={article} key={article.id} allNewsItems articles={cards}/>
            ))) 
          }
          { 
            allAgendaItems && (
            cards.map((article) => (
              <Card article={article} key={article.id} allAgendaItems/>
            )))
          }
          </> 
        )}
      </div>
  );
}

CardsContainer.propTypes = {
};

export default CardsContainer;