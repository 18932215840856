const procedurePLU = [
    {
        id: 1,
        path: 'https://drive.google.com/file/d/1uxJBxvykTRB9Wu8H5JKf7Xj_PFgI70Mu/view?usp=drive_link',
        name: 'Actes de procédure',
    }
]

const rapportDePresentationPLU = [
    {
        id: 1,
        path: 'https://drive.google.com/file/d/13iLKAVsU1RGorN11XXeJ0cVGFeZr8JCy/view?usp=drive_link',
        name: 'Rapport de présentation',
    }
]

const paddPLU = [
    {
        id: 1,
        path: 'https://drive.google.com/file/d/1abaEkJV2E4hj5TCc8NvD6xmOpp9jcYbO/view?usp=drive_link',
        name: 'Projet d’Aménagement et de Développement Durables',
    }
]

const reglementPLU = [
    {
        id: 1,
        path: 'https://drive.google.com/file/d/1NYTOLKBCMXblxaFzzNLkNYF7Gn91pB4O/view?usp=drive_link',
        name: 'Annexe Espace Boisé Classé (EBC)',
    },
    {
        id: 2,
        path: 'https://drive.google.com/file/d/1GacikqMcvyuTHWgASONm-5fM_eLTvyt3/view?usp=drive_link',
        name: 'Liste des emplacements réservés (ER)',
    },
    {
        id: 3,
        path: 'https://drive.google.com/file/d/1E1wU3H2lzgQ6MfeQ8BUdnPtc226FgJbJ/view?usp=drive_link',
        name: 'Plan des hauteurs et des emprises'
    },
    {
        id: 4,
        path: 'https://drive.google.com/file/d/13W_C79a1lHG8CldsozVb5GTe3bKfdyuO/view?usp=sharing',
        name: 'Règlement'
    },
    {
        id: 5,
        path: 'https://drive.google.com/file/d/1o6UlMdm8RNvVjTEVb63p2c8LCJ3RsPLa/view?usp=drive_link',
        name: 'Zonage commune'
    }
]

const annexesPLU = [
    {
        id: 1,
        path: 'https://drive.google.com/file/d/16RXPT4F2a3aGy01vHqafN0iOoIecd8mx/view?usp=drive_link',
        name: 'Annexe Droit de Préemption Urbain (DPU)',
    },
    {
        id: 2,
        path: 'https://drive.google.com/file/d/1nfTrtej9dQj7u6PuH_v_bpnh9tt-pxUM/view?usp=drive_link',
        name: 'Annexe bruit des infrastructures',
    },{
        id: 3,
        path: 'https://drive.google.com/file/d/1ckGRGYkiBhde1eZuRl3lbqxyNdP2LYK9/view?usp=drive_link',
        name: 'Annexe risque d’exposition au plomb',
    },{
        id: 4,
        path: 'https://drive.google.com/file/d/1abKz98nWfvF562odilDHl8xJ-hVbdG37/view?usp=drive_link',
        name: 'Annexes sanitaires',
    },{
        id: 5,
        path: 'https://drive.google.com/file/d/1VFq2uDrUVZ3t-V5EyBicmfnP8jRJOZGh/view?usp=drive_link',
        name: 'Annexe périmètre relatif au taux de la taxe d’aménagement',
    },{
        id: 6,
        path: 'https://drive.google.com/file/d/1C8SoIyTWjK1CGJFBsEq62ZMukNBDQV6j/view?usp=sharing',
        name: 'Annexe sites et sols pollués',
    },{
        id: 7,
        path: 'https://drive.google.com/file/d/1rZEkA8e9MSMrVKuaNoRxgUZIcDB_gRli/view?usp=drive_link',
        name: 'Annexe Biodiversité',
    },{
        id: 8,
        path: 'https://drive.google.com/file/d/1rant5K5CwVZMH42W--wO1HWxhYHg8533/view?usp=drive_link',
        name: 'Annexe Obligations Légales de Débroussaillement (OLD)',
    },{
        id: 9,
        path: 'https://drive.google.com/file/d/1OZEgjbVp11Yv07reXRNx5xa7UpRUB7AR/view?usp=drive_link',
        name: 'Annexe Ajustement Périmètre de Protection MH (PDA)',
    },{
        id: 10,
        path: 'https://drive.google.com/file/d/1G0-VJtWC5O1cx3lFJj0dLi7QtWqfeTsG/view?usp=drive_link',
        name: 'Annexe Plan de Prévention du Risque Inondation (PPRI)',
    },{
        id: 11,
        path: 'https://drive.google.com/file/d/1slyNOYrTtTN2TNMbuaZTiHcfoNOVSkO5/view?usp=drive_link',
        name: 'Annexe Risques Majeurs',
    },{
        id: 12,
        path: 'https://drive.google.com/file/d/10m8X5WalubZaY7WQmfUJ6dYAP31YQS4X/view?usp=drive_link',
        name: 'Liste des annexes dans le dossier : « 04_Annexes »',
    },{
        id: 13,
        path: 'https://drive.google.com/file/d/1Um6hq5op1S8l8y6e4IzCLXnKWhkHG1nf/view?usp=drive_link',
        name: 'Liste des Servitudes d’Utilité Publique',
    },{
        id: 14,
        path: 'https://drive.google.com/file/d/1gGgmI-3CTzmD3huBx2pQlEz2PDAVClgh/view?usp=drive_link',
        name: 'Plan de servitudes d’utilité publique',
    },{
        id: 15,
        path: 'https://drive.google.com/file/d/1fYbE7UaYZgmjJlUY00XvZaZhK741Xng0/view?usp=drive_link',
        name: 'Textes liés aux Servitudes d’Utilité Publique',
    }
]

const orientationsAmenagementPLU = [
    {
        id: 1,
        path: 'https://drive.google.com/file/d/1_cryy9lUTpZ3oRBBdTTX1nyYCHehVIFJ/view?usp=drive_link',
        name: 'Orientations d’Aménagement et de Programmation (O.A.P.)',
    }
]



export { procedurePLU, rapportDePresentationPLU, paddPLU, reglementPLU, annexesPLU, orientationsAmenagementPLU };
