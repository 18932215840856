import api from './utils/api';

import {
    GET_INFOS,
    saveInfos,
    UPDATE_CITY_INFOS, 
    getCityInfos
  } from '../actions/city';
  
  
  const citymiddleware = (store) => (next) => (action) => {
    switch (action.type) {

        case GET_INFOS:
            api({
                method: 'GET',
                url: `cities/1`,
                })
            .then((response) => {
                store.dispatch(saveInfos(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
            break;

        case UPDATE_CITY_INFOS:
            api({
                method: 'PATCH',
                url: `cities/1`,
                data: {
                    numberOfResidents: action.infos.residents,
                    openingHours: action.infos.openingHours,
                    nameOfMayor: action.infos.mayor
                }
                })
            .then((response) => {
                store.dispatch(getCityInfos());
            })
            .catch((error) => {
                console.log(error);
            });
            break;

      
      default:
        next(action);
    }
  };
  
  export default citymiddleware;