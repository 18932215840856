import React from 'react';

import { dateOfFileConverter, dateOfFileWithoutDayConverter } from './../../../selectors/date';

function PdfFiles({files, page, fileName}) {

  let isArrayOfArray = false;
  if (files) {
    if (Array.isArray(files[0])) {
      isArrayOfArray = true;
    }
  }
  
  return (
    <div>
      <p className='page__content__container__element__description' dangerouslySetInnerHTML={{__html: page.content.replace(/\n/g, '<br />')}} />
      <h2> Fichiers </h2>
      {files ? (
        <ul>
          {!isArrayOfArray && files.map((file) => (
            <li key={file.id}>
              { file.category ?
                <a href={file.path} target='_blank' rel="noreferrer"> {fileName} 
                { file.category.name !== 'Compte-rendu du conseil municipal' ? (
                  <span> de { dateOfFileWithoutDayConverter(file.date) } </span>
                ) : (
                  <span> du { dateOfFileConverter(file.date) } </span>
                )}
                </a>
              :
                <a href={file.path} target='_blank' rel="noreferrer"> {file.name} </a>
              }
            </li>
          ))}
          
          {isArrayOfArray && files.map((year) => (
            <li key={year[0]}>
              {year[0]}
              <ul>
                {year[1].map((file) => (
                  <li key={file.id}>
                    <a href={file.path} target='_blank' rel="noreferrer"> {fileName} { file.category.name !== 'Compte-rendu du conseil municipal' ? (
                      <span> de { dateOfFileWithoutDayConverter(file.date) } </span>
                    ) : (
                      <span> du { dateOfFileConverter(file.date) } </span>
                    )} 
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      ) : (
        <p> Aucun fichier </p>
      )}
    </div>
  );
}

export default PdfFiles;
