import { connect } from 'react-redux';
import Administration from './../../components/Administration';
import { 
    uploadFile, 
    getCategories, 
    setIsSuccess, 
    setIsLoading, 
    getFiles,
    editFile,
    deleteFile
} from './../../store/actions/file';
import { updateCityInfos } from './../../store/actions/city';

const mapStateToProps = (state) => ({
    categories: state.file.categories,
    isSuccess: state.file.isSuccess,
    isLoading: state.file.isLoading,
    files: state.file.files,
    cityInfos: state.city.infos,
});

const mapDispatchToProps = (dispatch) => ({
    uploadFile: (formData) => {
        dispatch(uploadFile(formData));
    },

    getCategories : () => {
        dispatch(getCategories());
    },

    setIsSuccess: (isSuccess) => {
        dispatch(setIsSuccess(isSuccess));
    },
    setIsLoading: (isLoading) => {
        dispatch(setIsLoading(isLoading));
    },

    getFiles: () => {
        dispatch(getFiles());
    },

    editFile: (id, editedFile) => {
        dispatch(editFile(id, editedFile));
    },

    deleteFile: (id, publicId) => {
        dispatch(deleteFile(id, publicId));
    },

    updateCityInfos: (cityInfos) => {
        dispatch(updateCityInfos(cityInfos));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Administration);