import React from 'react';
//import PropTypes from 'prop-types';
import Card from './../../../components/MyCard';
import './style.scss';


function News({newsData}) {
  
  if (newsData === undefined || newsData === null || newsData.length === 0) {
    return null;
  }

  newsData.sort((a, b) => {
    return new Date(b.published_at) - new Date(a.published_at);
  });

  let isMobile = false;
  if (window.innerWidth < 768) {
    isMobile = true;
  }
  
  return (
    <>
      <div className="news">
        <h2 className='homeSectionTitle'> <b>Actu</b>alité</h2>
        <div className="news__content">
            <div className='news__articles'>
              {isMobile ? (
                <>
                  {newsData.length === 0 && (
                    <p>Il n'y a pas d'actualité pour le moment</p>
                  )}
                  {newsData.length === 1 && (
                    <div className='news__articles__first'>
                      <Card article={newsData[0]} key={newsData[0].id} firstNewsItem/>
                    </div>
                  )}
                  {newsData.length > 1 && newsData.length <= 4 && (
                    <div className='news__articles__others'>
                      {newsData.map((article) => (
                        <Card article={article} key={article.id} secondsNewsItems/>
                      ))}
                    </div>
                  )}
                  {newsData.length > 4 && (
                    <div className='news__articles__others'>
                      {newsData.slice(0, 4).map((article) => (
                        <Card article={article} key={article.id} secondsNewsItems/>
                      ))} 
                    </div>
                  )}
                </>
              ) : (
                <>
                {newsData.length === 0 && (
                    <p>Il n'y a pas d'actualité pour le moment</p>
                  )}
                {newsData.length === 1 && (
                  <div className='news__articles__first'>
                    <Card article={newsData[0]} key={newsData[0].id} firstNewsItem/>
                  </div>
                )}
                  {newsData.length > 1 && (
                    <>
                      <div className='news__articles__first'>
                        <Card article={newsData[0]} key={newsData[0].id} firstNewsItem/>
                      </div>
                      <div className='news__articles__others'>
                        {newsData.slice(1, 4).map((article) => (
                          <Card article={article} key={article.id} secondsNewsItems/>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
        </div>
        <button className='homeSectionButton'>
          <a href='/actualite' className='homeSectionButton__link'>Toute les actualités</a>
        </button>    
          
      </div>
    </>
  );
}

News.propTypes = {
};

export default News;