import React from 'react';
import GoogleMapReact from 'google-map-react';
//import { Link } from 'react-router-dom';

import './style.scss';

import logoPiemontCevenol from './../../assets/images/logo-piemont-cevenol.png';
import logoGard from './../../assets/images/logo-gard.jpeg';
import logoOccitanie from './../../assets/images/logo-occitanie.png';
import logoVoisinsVigilants from './../../assets/images/logo-voisins-vigilants.png';
import logoZeroPhyto from './../../assets/images/logo-zero-phyto.jpeg';



function Footer({
  cityInfos,
}) {

  const logos = [
    {
      id: 1,
      title: 'Logo Piemont Cévenol',
      path: logoPiemontCevenol,
      url: 'https://www.piemont-cevenol.fr/',
    },
    {
      id: 2,
      title: 'Logo Gard',
      path: logoGard,
      url: 'https://www.gard.fr/1.html',
    },
    {
      id: 3,
      title: 'Logo Occitanie',
      path: logoOccitanie,
      url: 'https://www.laregion.fr/',
    },
    {
      id: 4,
      title: 'Logo Voisins Vigilants',
      path: logoVoisinsVigilants,
      url: 'https://www.voisinsvigilants.org/',
    },
    {
      id: 5,
      title: 'Logo Zéro Phyto',
      path: logoZeroPhyto,
      url: 'http://www.zerophyto.fr/',
    },
  ]
  
  const defaultProps = {
    center: {
      lat: 44.0263,
      lng: 4.08033
    },
    zoom: 15
  };

  return (
    <div className='footer'>
      <div className="footer__container">
        <div className="footer__container__infos">
          <p className="footer__container__infos__title" > Mairie de Cardet </p>
          <div> Place de la Mairie </div>
          <div> 30350 Cardet </div>
          <div> Tél : 04 66 83 81 42 </div>
          <p>Mail : <a href="mailto:mairiedecardet@orange.fr" className="footer__container__infos__link"> mairiedecardet@orange.fr </a> </p>
          <p> {cityInfos.openingHours} </p>
          <div className="footer__container__icons">
          {logos.map((logo) => (
            <a href={logo.url} key={logo.id} target='_blank' rel='noreferrer'> <img src={logo.path} alt={logo.title} className="footer__container__icons__icon" /> </a>
          ))}
        </div>
        </div>
        <div className="footer__container__plan">
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            />
        </div>
      </div>
      <p className='footer__mentions'>Designed by <a href={`mailto:${process.env.REACT_APP_DESIGNED_BY_EMAIL}`}> Rosa </a></p>
    </div>
  );
};

export default Footer;