import React, { useState } from 'react';

import { dateOfFileConverter } from '../../../selectors/date';

export default function FileList({
    files,
    editFile,
    categories,
    deleteFile
}) {

  const [editedFile, setEditedFile] = useState(null);
  const [sortCriteria, setSortCriteria] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const handleEdit = (file) => {
    setEditedFile(file);
  };

  const handleCancelEdit = () => {
    setEditedFile(null);
  };


  const handleDateChange = (e) => {
    editedFile.date = e.target.value;
  };

  const handleCategoryIdChange = (e) => {
    editedFile.category = e.target.value;
  };

  const handleSaveEdit = (file) => {
    editFile(file.id, editedFile)

    setEditedFile(null);
  };

  const handleDelete = (file) => {
    deleteFile(file.id, file.publicId);

    setEditedFile(null);
  };

  // Function to handle sorting when the table headers are clicked
  const handleSort = (criteria) => {
    // If the same criteria is clicked, reverse the sort order
    if (sortCriteria === criteria) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortCriteria(criteria);
      setSortOrder('asc'); // By default, set to ascending order when a new criteria is clicked
    }
  };

  // Sort files based on the selected criteria and sort order
  const sortedFiles = files.slice().sort((a, b) => {
    // Implement your custom sorting logic here
    if (sortCriteria === 'category') {
      return sortOrder === 'asc'
        ? a.category.name.localeCompare(b.category.name)
        : b.category.name.localeCompare(a.category.name);
    } else if (sortCriteria === 'date') {
      return sortOrder === 'asc'
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    } else {
      // Return 0 to maintain the original order if no sorting criteria is selected
      return 0;
    }
  });

  return (
    <div className="file-list mt-3">
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col" onClick={() => handleSort('category')}>
              Nom du fichier
              <i className='bi bi-arrow-down-up m-1'></i>
            </th>
            <th scope="col" onClick={() => handleSort('date')}>
              Date
              <i className='bi bi-arrow-down-up m-1'></i>
            </th>
            <th scope="col" onClick={() => handleSort('category')}>
              Catégorie
              <i className='bi bi-arrow-down-up m-1'></i>
            </th>
            <th scope="col">Fichier</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
      <tbody>
        {/* Render the sortedFiles array instead of the original files array */}
        {sortedFiles.map((file, index) => (
          <React.Fragment key={index}>
              <tr>
                <th scope="row">{file.category.name} du { dateOfFileConverter(file.date)}</th>
                <td>{dateOfFileConverter(file.date)}</td>
                <td>{file.category.name}</td>
                <td>
                    <a href={file.path} target='_blank' rel="noreferrer"> {file.category.name} du { dateOfFileConverter(file.date)} </a>
                </td>
                <td>
                  <button onClick={() => handleEdit(file)} className='btn btn-link d-inline'><i className="bi bi-pencil-fill"></i></button>
                  <button onClick={() => handleDelete(file)} className='btn btn-link d-inline'><i className="bi bi-trash3-fill"></i></button>
                </td>
                
              </tr>
              {editedFile === file && (
                <tr>
                  <td></td>
                  <td>
                    <input type="date" onChange={handleDateChange} />
                  </td>
                  <td>
                    <select
                      name="categoryId"
                      id="categoryId"
                      onChange={handleCategoryIdChange}
                  >
                      <option value="">Choisir une catégorie</option>
                      {categories && categories.map((category) => (
                          <option key={category.id} value={category.id}>
                              {category.name}
                          </option>
                      ))}
                    </select>
                  </td>
                  <td></td>
                  <td>
                    <button onClick={() => handleSaveEdit(file) } className='btn btn-link d-inline'><i className="bi bi-check-circle-fill"></i></button>
                    <button onClick={handleCancelEdit} className='btn btn-link d-inline'><i className="bi bi-x-circle-fill"></i></button>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
