import React from 'react';
import './style.scss';

import FirstNewsItem from './FirstNewsItem';
import SecondsNewsItems from './SecondsNewsItems';
import AllNewsItems from './AllNewsItems';
import AgendaItems from './AgendaItems';
import AllAgendaItems from './AllAgendaItems';

function MyCard({article, firstNewsItem, secondsNewsItems, allNewsItems, agendaItems, allAgendaItems, articles}) {

  switch (true) {
    case firstNewsItem:
      return <FirstNewsItem article={article} />;
    case secondsNewsItems:
      return <SecondsNewsItems article={article} />;
    case allNewsItems:
      return <AllNewsItems article={article} articles={articles} />;
    case agendaItems:
      return <AgendaItems article={article} />;
    case allAgendaItems:
      return <AllAgendaItems article={article} />;
    default:
      return <div>Erreur</div>;
  }
  
}

MyCard.propTypes = {
};

export default MyCard;