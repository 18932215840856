import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import './style.scss';
import image from './../../../assets/images/plan-de-Cardet.jpg';



function Plan() {

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  
  return (
      <div className="plan">
        <h2 className='homeSectionTitle'> <b> Plan </b> de la commune</h2>
        <img src={image} alt="plan de Cardet" className="plan__image"  onClick={handleClick} />
        
        {isOpen && 
          <div className="plan__open" onClick={handleClose}>
            <img src={image} alt="plan de Cardet" className="plan__open__image" />
          </div> 
        }
      </div>
  );
}

Plan.propTypes = {
};

export default Plan;