import { combineReducers } from 'redux';
import weatherReducer from './weather';
import intraMurosReducer from './intraMuros';
import fileReducer from './file';
import cityReducer from './city';


const rootReducer = combineReducers({
    weather: weatherReducer,
    intraMuros: intraMurosReducer,
    file: fileReducer,
    city: cityReducer,
});

export default rootReducer;