/**
 *  on trouve la page voulue dans la liste des pages
 * @param {Array} pages - toutes les pages
 * @return {Object} - La page trouvée
 */
export function findPage(pages) {

  // url of the current page
  let currentPage = window.location.pathname;
  
  let page = pages.find((testedPage) => {
    return testedPage.path === currentPage;
  });
  return page;
}