export const companiesCategories = [
    {
        id: 1,
        name: "Commerces de proximité",
    },
    {
        id: 2,
        name: "Rénovation & travaux d'intérieur",
    },
    {
        id: 3,
        name: "Garages",
    },
    {
        id: 4,
        name: "Zone artisanale",
    },
    {
        id: 5,
        name: "Maçonnerie & travaux publics",
    },
    {
        id: 6,
        name: "Bars & restaurants",
    },
]


export function getAllCompaniesofCategory(categoryId) {
    return companies.filter((company) => company.categorie === categoryId);
}

const companies = [
    {
        id: 1,
        name: "BOULANGERIE",
        description: "Vente de pain, viennoiseries et pâtisseries. Gâteau sur commande.",
        president: "Christian BOISSIER",
        address: "Rue du Temple",
        tel: "04 66 83 85 67",
        categorie: 1,
    },
    {
        id: 2,
        name: "CAVE COOPERATIVE DES VIGNERONS",
        description: "",
        president: "",
        address: "102 rue des Cèdres",
        tel: "04 66 83 00 63",
        categorie: 1,
    },
    {
        id: 3,
        name: "CEVENNES CARAVANES",
        description: "Achat, vente, dépôt-vente, neuf et occasion, gardiennage, accessoires, ateliers, réparation et location",
        president: "",
        address: "401 route de Montpellier",
        tel: "04 66 83 00 35",
        link: "http://www.cevennescaravanes.com/",
        target: "_blank",
        categorie: 1, 
    },
    {
        id: 4,
        name: "DOMAINE DES ARNASSEAUX",
        description: "Famille Peladan, vignerons de père en fils – cave particulière",
        president: "",
        address: "421 Chemin des Arnasseaux",
        tel: "06 82 65 69 62",
        categorie: 1,
    },
    {
        id: 5,
        name: "LE HARAS ET MAS DE FONT GRANADA",
        description: "Location de salle de fête; élevage & vente de chevaux ibériques",
        president: "Damien DONZALA",
        address: "547 chemin des Arnasseaux",
        tel: "06 20 85 78 19",
        categorie: 1,
    },
    {
        id: 6,
        name: "LAURA HUISMAN PHOTOGRAPHE",
        description: "Photographe grossesse, nouveau né, famille",
        president: "",
        address: "1, avenue du stade",
        tel: "07 88 35 79 86",
        link: "https://www.laurahuismanphotographe.com/",
        target: "_blank",
        categorie: 1,
    },
    {
        id: 7,
        name: "LOUIS GULDENTOPS",
        description: "Création & hébergement de sites internet",
        president: "",
        address: "932 route des Cévennes",
        tel: "06 38 21 72 67",
        categorie: 1,
    },
    {
        id: 8,
        name: "REFLEXOLOGIE PLANTAIRE",
        description: "Bien-être physique, psychique et émotionnel",
        president: "Marie-Eve Gauthier",
        address: "229 chemin de la Salée",
        tel: "06 58 26 74 00",
        mail: "reflexologieplantaire.mg@gmail.com",
        link: "https://www.reflexologieplantaire-mg.com/",
        target: "_blank",
        categorie: 1,
    },
    {
        id: 9,
        name: "VGL FLOCAGE",
        description: "Flocage et impression textile, panneau publicitaire, autocollant",
        president: "",
        address: "245 Route de Montpellier",
        tel: "06 76 09 71 16",
        categorie: 1,
    },
    {
        id: 10,
        name: "JL PROTECTION",
        description: "Vente, installation et entretien de système d’alarme et vidéo; devis gratuit",
        president: "",
        address: "4 lot les jardins de Cardet",
        tel: "04 66 34 53 90 / 06 09 52 93 42",
        categorie: 2,
    },
    {
        id: 11,
        name: "M.L ENERGIE",
        description: "Attestation BBIO - Energies renouvelables RT2012 & Développement durable",
        president: "",
        address: "",
        tel: "06 60 96 32 58",
        mail: "m.ldiagnostic@laposte.net",
        categorie: 2,
    },
    {
        id: 12,
        name: "SOPHROMAG",
        description: "Sophrologie",
        president: "Magali LOHR",
        address: "10 rue de l'ancienne loge",
        tel: "06 70 24 26 27",
        mail: "mlohr.sophrologue@gmail.com",
        link: "http://www.sophromag.fr/",
        target: "_blank",
        categorie: 1,
    },
    {
        id: 13,
        name: "KPR",
        description: "Peinture intérieur & extérieur, décoration, neuf & rénovation, devis gratuit",
        president: "Kevin Piquepaille",
        address: "",
        tel: "06 63 40 55 64",
        mail: "kevin.kpr@gmail.com",
        categorie: 2,
    },
    {
        id: 45,
        name: "CB POSE",
        description: "Pose de cuisine, salle de bain et dressing",
        president: "Christian Bouchet",
        address: "",
        tel: "07 83 97 82 87",
        mail: "",
        categorie: 2,
    },
    {
        id: 14,
        name: "BRICO DECO 30",
        description: "Multiservices du batiment",
        president: "",
        address: "",
        tel: " 06 17 48 91 85 / 04 66 56 80 20",
        categorie: 2,
    },
    {
        id: 15,
        name: "DECORAZUR",
        description: "Peintre décorateur Intérieur & Extérieur",
        president: "",
        address: "",
        tel: "06 19 40 60 86",
        categorie: 2,
    },
    {
        id: 16,
        name: "DB PRESTATIONS",
        description: "Électricité générale en bâtiment",
        president: "Bruno DUBOIS",
        address: "9 Chemin des Murailles",
        tel: "04 66 30 46 03 / 06 99 45 93 67",
        categorie: 2,
    },
    {
        id: 17,
        name: "N.D ELEC",
        description: "Electricité générale, climatisation, antenne",
        president: "Nicolas DUMAS",
        address: "",
        tel: "06 22 35 70 73",
        mail: "nicolasdumas.elec@gmail.com",
        categorie: 2,
    },
    {
        id: 18,
        name: "CEVENNES EXPERTISES",
        description: "Diagnostiqueur immobilier",
        president: "Geoffray BALDIT",
        address: "",
        tel: "04 67 64 01 53 / 06 01 94 08 73",
        mail: " geoffray.baldit@cevennes-expertises.fr",
        categorie: 2,
    },
    {
        id: 19,
        name: "CLIM’ SERVIVES 30",
        description: "Pose de climatisation, Entretien et dépannage, Electricité et plomberie",
        president: "Francis HEDOIN",
        address: "",
        tel: "06 68 67 08 89",
        mail: "f.hedoin@yahoo.com",
        categorie: 2,
    },
    {
        id: 20,
        name: "MTSI",
        description: "Vente et entretien de matériel incendie. Vérificateur diplômé d’Etat",
        president: "Régis LARCIN",
        address: "",
        tel: "06 75 35 59 10",
        mail: "mtsi.rl@outlook.fr",
        categorie: 2,
    },
    {
        id: 21,
        name: "HEAT CLIM",
        description: "Climatisation, électricité, plomberie, chauffage",
        president: "Cédric LEBERT",
        address: "95 rue des cèdres",
        tel: "06 42 39 68 12",
        categorie: 2,
    },
    {
        id: 22,
        name: "MIKAELEC",
        description: "Electricité générale, neuf, rénovation, dépannage",
        president: "",
        address: "",
        tel: "06 88 37 48 28",
        mail: "mikaelo1908@outlook.fr",
        categorie: 2,
    },
    {
        id: 23,
        name: "GARAGE MARTORANA",
        description: "Auto Carrosserie, peinture, vente véhicules d’occasion, dépannage 24h/24 et 7j/7",
        president: "M et Mme MARTORANA",
        address: "Zone Artisanale Mas Dursy",
        tel: "04 66 83 88 84 / 06 16 72 65 47",
        categorie: 3,
    },
    {
        id: 24,
        name: "GARAGE DU PONT TROUE",
        description: "Dépannage 7j/7, carrosserie peinture, mécanique, diagnostic, climatisation, voitures de courtoisie",
        president: "M et Mme WIESER",
        address: "1104, Route de Montpellier – Quartier Lauzier du Font Granada",
        tel: "04 66 85 71 87 / 06 22 98 91 31",
        categorie: 3,
    },
    {
        id: 25,
        name: "MECA-DEPANNE",
        description: "Mécanique générale, dépannage à domicile",
        president: "Cyril BENOIT",
        address: "",
        tel: "06 61 00 54 05",
        categorie: 3,
    },
    {
        id: 26,
        name: "S.A.R.L. GARAGE GAUCHE PERE & FILS",
        description: "Mécanique générale, diésel hydraulique, AGR, VL, TP Atelier et dépannage à domicile",
        president: "",
        address: "",
        tel: "04 66 83 05 23 / 06 09 66 30 96",
        categorie: 3,
    },
    {
        id: 27,
        name: "MAZA MECA",
        description: "Maître artisan en mécanique automobile, réparation et entretien automobiles, diagnostic électronique, montage et équilibrage des pneumatiques",
        president: "Olivier SALELLES",
        address: "191 chemin de Maza",
        tel: "06 64 19 40 62",
        categorie: 3,
    },
    {
        id: 28,
        name: "ENTREPRISE VETSEL",
        description: "Eclairage public, domotique, industrie, chauffage & climatisation",
        president: "René VETSEL",
        address: "Zone Artisanale La Distillerie",
        tel: "04 66 83 85 10",
        categorie: 4,
    },
    {
        id: 29,
        name: "S&P RENFORCEMENT FRANCE",
        description: "Fixation, scellement chimique",
        president: "Jean-Pierre CARTIER",
        address: "173 Zone Industrielle/Artisanale Les Mourgues",
        tel: "04 66 83 87 92",
        link: "https://www.sp-reinforcement.fr/fr-FR/",
        target:"_blank",
        categorie: 4,
    },
    {
        id: 30,
        name: "LA POTERIE « LES TERRES ANCIENNES »",
        description: "Fabrication et vente de vases d’Anduze. Du lundi au samedi de 9h à 12h et de 14h à 18h.",
        president: "Franck BECKER",
        address: "76, ZA les mourgues",
        tel: "06 82 21 86 80",
        categorie: 4,
    },
    {
        id: 31,
        name: "SAB PLAQUISTE",
        description: "Plafond – Doublage – Cloison – Isolation",
        president: "",
        address: "71 ZA Les Mourgues",
        tel: "04 66 07 21 75",
        mail: "sab36@wanadoo.fr",
        categorie: 4,
    },
    {
        id: 32,
        name: "STE DURANDET PERE & FILS",
        description: "Travaux en bâtiment, espaces verts",
        president: "",
        address: "Les Mas de Cardet",
        tel: "04 66 83 81 49",
        categorie: 5,
    },
    {
        id: 33,
        name: "SARL MEJEAN",
        description: "Maçonnerie Maçonnerie générale",
        president: "",
        address: "ZA Les Mourgues",
        tel: "06 77 17 20 11",
        categorie: 5,
    },
    {
        id: 34,
        name: "SANKOUR ABDELILAH",
        description: "Maçonnerie générale, rénovation, construction villas, carrelage, enduits, façade",
        president: "",
        address: "Les Arnasseaux – Chemin des cyprès",
        tel: "06 24 49 66 91 / 04 66 83 40 31",
        mail: "open2@hotmail.fr",
        categorie: 5,
    },
    {
        id: 35,
        name: "AIGOIN MACONNERIE",
        description: "",
        president: "",
        address: "",
        tel: "06 62 73 71 48",
        categorie: 5,
    },
    {
        id: 36,
        name: "BERNARD THIERRY",
        description: "Travaux de terrassement courants et travaux préparatoires",
        president: "",
        address: "170 Chemin du Dévès",
        tel: "09 69 80 48 67",
        categorie: 5,
    },
    {
        id: 37,
        name: "MARTORANA PATRICE",
        description: "entreprise de terrassements",
        president: "",
        address: "371 chemin des Jardins",
        tel: "",
        categorie: 5,
    },
    {
        id: 38,
        name: "COMPACT CONCASSAGE",
        description: "Travaux de terrassement, concassage de gravats",
        president: "Victor PALASSIN",
        address: "Chemin des mas",
        tel: "06 75 93 13 50",
        categorie: 5,
    },
    {
        id: 39,
        name: "BAR – TABAC BEAU RIVAGE",
        description: "Ouvert tous les jours de 7H30 à minuit",
        president: "Mr & Mme ROUX",
        address: " 20, Rue du Temple",
        tel: "04 66 83 00 17",
        categorie: 6,
    },
    {
        id: 40,
        name: "BAR – TABAC LE RELAIS DU PONT TROUE",
        description: "",
        president: "Mr & Mme PIC",
        address: "Route des Cévennes",
        tel: "04 66 83 00 53",
        categorie: 6,
    },
    {
        id: 41,
        name: "RESTAURANT LE PONT TROUE",
        description: "",
        president: "",
        address: "2416 Route des Cévennes",
        tel: "04 66 83 50 31",
        categorie: 6,
    },
    {
        id: 42,
        name: "RESTAURANT « L’ESSENTIEL »",
        description: "Ambiance bistro chic – Cuisine raffinée – Produits locaux de qualités",
        president: "",
        address: "47 ZA Les Mourgues",
        tel: "04 34 13 50 81",
        link: "https://www.facebook.com/people/LEssentiel-Cardet/100063576610128/",
        target:"_blank",
        categorie: 6,
    },
    {
        id: 43,
        name: "RESTAURANT DU CAMPING DU CHERCHEUR D’OR",
        description: "Cuisine traditionnelle. Ouvert en période estivale",
        president: "",
        address: "",
        tel: "04 66 83 82 44",
        categorie: 6,
    },
    {
        id: 44,
        name: "PIZZERIA DU CAMPING BEAU RIVAGE",
        description: "Pizzas au feu de bois, salades repas et grillades. Sur place ou à emporter. Ouvert d’avril à fin septembre.",
        president: "",
        address: "22 chemin du Bosquet",
        tel: "07 50 52 92 44",
        link: "https://www.facebook.com/beaurivage30",
        target:"_blank",
        categorie: 6,
    },
    {
        id: 45,
        name: "PIZZERIA LES MASIERS",
        description: "Pizzas à emporter cuites au feu de bois.",
        president: "",
        address: "",
        tel: "06 19 56 07 96",
        link: "https://www.facebook.com/people/Pizza-des-Masiers/100063703412639/",
        target:"_blank",
        categorie: 6,
    }

]

