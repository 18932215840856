import React from 'react';
//import PropTypes from 'prop-types';
//import Card from '../../MyCard';
import './style.scss';
import { findCommissionName } from './../../../data/conseilMunicipal';
import commissions from './../../../assets/images/conseilMunicipal/commissions.pdf';


function ConseilMunicipal({data}) {

  // if on mobile device, change data order : the third element must be the first
  const isMobile = window.innerWidth < 768;
  if (isMobile) {
    let elementToMove = data.splice(2, 1)[0];
    data.unshift(elementToMove);
  }

  
  return (
      <div className="conseilMunicipal">
        {data.map((conseiller) => (
          <div className="conseilMunicipal__conseiller" key={conseiller.id}>
            <img 
                className='conseilMunicipal__conseiller__image' 
                src={conseiller.picture} 
                alt={conseiller.name} 
              />
            <h4 className='conseilMunicipal__conseiller__name'>{conseiller.name}</h4>
            <p className='conseilMunicipal__conseiller__role'><b>{conseiller.role}</b></p>
            {conseiller.delegation ? <p className='conseilMunicipal__conseiller__role'><b>{conseiller.delegation}</b></p> : null}
            { conseiller.responsabiliteIds ? 
              <>
                <p className='conseilMunicipal__conseiller__responsabilite'> <b>Responsable commission</b></p>
                <div> 
                  {conseiller.responsabiliteIds.map((id) => {
                  return <p className='conseilMunicipal__conseiller__role'> { findCommissionName(id) } </p>
                  })}
                </div>
              </>
            : null }
            { conseiller.commissionsIds ?
              <>
                <p className='conseilMunicipal__conseiller__commissions'> <b>Commissions</b> </p> 
                <div> 
                  {conseiller.commissionsIds.map((id) => {
                    return <p className='conseilMunicipal__conseiller__role'> { findCommissionName(id) } </p>
                  })}
                </div> 
              </>
            : null }
            { conseiller.autre ? <p className='conseilMunicipal__conseiller__role'><b>{conseiller.autre}</b></p> : null }
          </div>
        ))}
        
        <div className='conseilMunicipal__infos'>
          <div>
            <h4 className='mb-3'>Abréviations</h4>
            <p><b>ADE</b> : Aménagement de l’espace </p>
            <p><b>CCAS</b> : Centre Communal d’Action Social</p>
            <p><b>CCPC</b> : Communauté de Communes du Piémont Cévenol</p>
            <p><b>DEV éco</b> : Développement économique</p>
            <p><b>Env et TE</b> : Environnement et Transition Energétique</p>
            <p><b>PCS</b> : Plan Communal de Sauvegarde</p>
            <p><b>PPRI</b> : Plan de Prévention du Risque Inondation</p>
            <p><b>PST</b> : Projet Social Territorialisé</p>
          </div>
          <div>
            <h4 className='mb-3'>Glossaire</h4>
            <p><b>Communication</b> : café citoyen, Facebook, Le Cardésien, site internet, si Cardet m’était conté </p>
            <p><b>Cérémonies Municipales</b> : vœux,1er mai, 8 mai, 11 novembre, etc. </p>
            <p><b>Culture</b> : fête de la musique, cinéma, journées européennes du patrimoine, Téléthon </p>
            <p><b>Environnement</b> : espaces verts, transition énergétique, cimetière, compostage, énergie, éclairage, embellissement de la commune, etc.  </p>
            <p><b>Centre communal d’action sociale</b> : CCAS, colis de noël aux aînés, repas des aînés, etc. </p>
            <p><b>Bâtiments</b> : école des mas, foyer, salle des associations </p>
          </div>
        </div>

        <a className='m-auto' href={commissions}> <button className='homeSectionButton'> Les commissions </button> </a>
      
      </div>
  );
}

ConseilMunicipal.propTypes = {
};

export default ConseilMunicipal;