import {
    SAVE_WEATHER
  } from '../actions/weather';

  export const initialState = {
    weatherData: [],
  };

  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

      case SAVE_WEATHER:
        return {
          ...state,
            weatherData: action.weatherData,
        };
        
      default:
        return state;
    }
  };

  export default reducer;