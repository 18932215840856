import React from 'react';
// import PropTypes from 'prop-types';
import Header from './../../components/Header';
import News from './News';
import Agenda from './Agenda';
import homeImage from './../../assets/images/home_2023.png.webp';
import KeyData from './KeyData';
import Services from './Services';

import './style.scss';
import Plan from './Plan';
import Photos from './Photos';
import Footer from './../../containers/Footer';

function Home({ 
  weatherData, 
  agendaData, 
  newsData,
  cityInfos,
}) {

  return (
    <div className='home'>
      <Header />
      <img src={homeImage} alt="montage de cardet" className="home__image" />
      <Services />
      <News newsData={newsData} />
      <Agenda agendaData={agendaData} />
      <KeyData weatherData={weatherData} cityInfos={cityInfos} />
      <Plan />
      <Photos />
      <Footer />
    </div>
  );
 
};

Home.propTypes = {
};

export default Home;