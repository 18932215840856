export const GET_FILES = 'GET_FILES';
export const SAVE_FILES = 'SAVE_FILES';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SAVE_CATEGORIES = 'SAVE_CATEGORIES';
export const GET_FILES_BY_CATEGORY = 'GET_FILES_BY_CATEGORY';
export const SET_IS_SUCCESS = 'SET_IS_SUCCESS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const EDIT_FILE = 'EDIT_FILE';
export const DELETE_FILE = 'DELETE_FILE';


export const getFiles = () => ({
    type: GET_FILES,
});

export const saveFiles = (files) => ({
    type: SAVE_FILES,
    files,
});

export const uploadFile = (formData) => ({
    type: UPLOAD_FILE,
    formData
});

export const getCategories = () => ({
    type: GET_CATEGORIES,
});

export const saveCategories = (categories) => ({
    type: SAVE_CATEGORIES,
    categories,
});

export const setIsSuccess = (isSuccess) => ({
    type: SET_IS_SUCCESS,
    isSuccess,
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    isLoading,
});

export const editFile = (id, editedFile) => ({
    type: EDIT_FILE,
    id,
    editedFile
});

export const deleteFile = (id, publicId) => ({
    type: DELETE_FILE,
    id,
    publicId,
});