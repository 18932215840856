import { connect } from 'react-redux';
import Home from './../../components/Home';

const mapStateToProps = (state) => ({
    weatherData: state.weather.weatherData,
    agendaData: state.intraMuros.agendaData,
    newsData: state.intraMuros.newsData,
    cityInfos: state.city.infos,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);