export const GET_INFOS = 'GET_INFOS';
export const SAVE_INFOS = 'SAVE_INFOS';
export const UPDATE_CITY_INFOS = 'UPDATE_CITY_INFOS';

export const getCityInfos = () => ({
    type: GET_INFOS
});

export const saveInfos = (infos) => ({
    type: SAVE_INFOS,
    infos
});

export const updateCityInfos = (infos) => ({
    type: UPDATE_CITY_INFOS,
    infos
});
