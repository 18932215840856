import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import Header from './../../components/Header';
import { Navigate } from 'react-router-dom';
import './style.scss';
import CardsContainer from './CardsContainer';
import Calendar from './Calendar';
import List from './List';
import PdfFiles from './PdfFiles';
import Footer from './../../components/Footer';
import ConseilMunicipal from './ConseilMunicipal';
import Mairie from './Mairie';

import conseilMunicipalData from './../../data/conseilMunicipal';
import { getAllAssociationsofCategory, associationsCategories } from './../../data/associations';
import { getAllCompaniesofCategory, companiesCategories } from './../../data/companies';
import { procedurePLU, rapportDePresentationPLU, paddPLU, reglementPLU, annexesPLU, orientationsAmenagementPLU } from './../../data/plu';
import { sortFilesByYear } from './../../selectors/file'; 

function Page({
  page, 
  newsData, 
  agendaData, 
  filesByCategory, 
  getFiles, 
  cityInfos
}) {

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const [picture, setSelectedPicture] = useState(null);

  const handleClick = (event) => {
    const picture = event.target.src;
    setSelectedPicture(picture);
  };

  if (!page) {
    return <Navigate to="/error" />;
  }

  const path = window.location.pathname;

  const isArray = (value) => {
    return value && typeof value === 'object' && value.constructor === Array;
  }

  return(
    <>
     <Header/>

      <div className="page">
            <h1 className='page__title'>{page.name}</h1>

            {(() => {
              switch (path) {
                case '/vie-quotidienne/la-mairie-les-elus/comptes-rendus-du-conseil-municipal': 
                  return (
                    <PdfFiles 
                      page={page} 
                      files={filesByCategory['Compte-rendu du conseil municipal'] && sortFilesByYear(filesByCategory['Compte-rendu du conseil municipal'])}
                      fileName='Compte-rendu du conseil municipal' 
                    />
                  );
                case '/vie-quotidienne/citoyennete/cafes-citoyens' : 
                  return (
                    <PdfFiles 
                      page={page} 
                      files={filesByCategory['Compte-rendu du café citoyen'] && sortFilesByYear(filesByCategory['Compte-rendu du café citoyen'])}
                      fileName='Compte-rendu du café citoyen' 
                    />
                    );
                case '/vie-quotidienne/citoyennete/bien-vivre-ensemble' : 
                  return (
                    <PdfFiles 
                      page={page} 
                      files={filesByCategory['Bien vivre ensemble'] && filesByCategory['Bien vivre ensemble']}
                      fileName='Bien vivre ensemble' 
                    />
                    );
                case '/vie-quotidienne/bulletin-municipal-le-cardesien' : 
                  return (
                    <PdfFiles 
                      page={page} 
                      files={filesByCategory['Cardésien'] && sortFilesByYear(filesByCategory['Cardésien'])}
                      fileName='Cardésien' 
                    />
                    );
                case '/territoire/plan-local-durbanisme/procedure' : 
                  return <PdfFiles 
                    page={page} 
                    files={procedurePLU} 
                    fileName='Procédure'
                  />;
                case '/territoire/plan-local-durbanisme/rapport-de-presentation' : 
                  return <PdfFiles 
                    page={page} 
                    files={rapportDePresentationPLU} 
                    fileName='Rapport de présentation'  
                  />;
                case '/territoire/plan-local-durbanisme/padd' :
                  return <PdfFiles 
                    page={page} 
                    files={paddPLU} 
                    fileName='PADD'  
                  />;
                case '/territoire/plan-local-durbanisme/reglement' :
                  return <PdfFiles 
                    page={page} 
                    files={reglementPLU} 
                    fileName='Règlement'  
                  />;
                case '/territoire/plan-local-durbanisme/annexes' :
                  return <PdfFiles 
                    page={page} 
                    files={annexesPLU} 
                    fileName='Annexes'  
                  />;
                case '/territoire/plan-local-durbanisme/orientations-damenagement' :
                  return <PdfFiles
                    page={page}
                    files={orientationsAmenagementPLU}
                    fileName="Orientations d'aménagement"
                  />;

                case '/actualite': return <CardsContainer cards={newsData} allNewsItems />;
                case '/agenda': return <Calendar cards={agendaData} />;
                case '/vie-quotidienne/la-mairie-les-elus/les-elus-commissions': return <ConseilMunicipal data={conseilMunicipalData} />;
                
                case '/vie-quotidienne/les-associations': return <List categories={associationsCategories} data={getAllAssociationsofCategory} isAssociation={true} />;
                case '/vie-quotidienne/commerces-entreprises-restaurants': return <List categories={companiesCategories} data={getAllCompaniesofCategory} isAssociation={false} />;
                case '/vie-quotidienne/infrastructures-communales/la-mairie-agence-postale': return <Mairie cityInfos={cityInfos} />;
                
                default: 
                return <>
                  <div className='page__content'>
                    {page.image && <img className='page__content__image' src={page.image} alt={page.name} onClick={handleClick} />}
                    {page.image2 && <img className='page__content__image' src={page.image2} alt={page.name} onClick={handleClick} />}


                    {picture && (
                      <div className="full-screen-photo">
                        <img
                          src={picture}
                          alt={page.name}
                          onClick={() => setSelectedPicture(null)}
                          className="full-screen-photo__image"
                        />
                      </div>
                    )}
                   
                    <div>
                      { isArray(page.content) ?
                        <div className='page__content__container' >
                          {page.content.map((element) => 
                          <div key={element.id} className='page__content__container__element'>
                            {element.image && <img className='page__content__container__element__image' src={element.image} alt={element.name} />}
                            <p className="list__category__element__name"> {element.name} </p>
                            { element.content && <p className='page__content__container__element__description' dangerouslySetInnerHTML={{__html: element.content.replace(/\n/g, '<br />')}} /> }
                            { element.address && <p className=""><b>Adresse</b> : {element.address}</p> }
                            { element.tel && <p className=""><b>Tél</b> : {element.tel}</p> }
                            { element.mail && <p className=""><b>E-mail</b> : {element.mail}</p> }
                            { element.link && <a className="list__category__element__link" href={element.link} target='_blank' rel='noreferrer'> <i className="bi bi-box-arrow-up-right me-2"></i> Site internet </a> }
                          </div>
                          
                          )}
                        </div>
                          :
                        <p className='page__content__description' dangerouslySetInnerHTML={{__html: page.content.replace(/\n/g, '<br />')}} />
                      }
                      { page.links && 
                        <ul>
                          {page.links.map((element) =>
                          <li key={element.id}><a className='page__content__link' href={element.path} target="_blank" rel="noopener noreferrer">{element.name} </a></li>
                          )}
                        </ul>
                      }
                      { page.files &&
                          <>
                            <h4> {page.filesTitle} </h4>
                            {page.files.map((element) =>
                              <img className='page__content__image' src={element.image} alt={element.name} onClick={handleClick} key={element.id}/>
                              )}
                          </>
                      }
                    </div>
                  </div>
                  
                </>;

              }
            })()}
      </div>

      <Footer cityInfos={cityInfos} />
    </>
  )
}

Page.propTypes = {
};

export default Page;