import {
    SAVE_AGENDA,
    SAVE_NEWS,
  } from '../actions/intraMuros';

  export const initialState = {
    agendaData: [],
    newsData: [],
  };

  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

      case SAVE_AGENDA:
        return {
          ...state,
            agendaData: action.agendaData,
        };

      case SAVE_NEWS:
        return {
          ...state,
            newsData: action.newsData,
        };
        
      default:
        return state;
    }
  };

  export default reducer;