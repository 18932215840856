import React from 'react';
//import PropTypes from 'prop-types';
import './style.scss';

import { makeDailySummary } from './../../../selectors/weather';
import { convertDayOfWeekToAbbreviation } from './../../../selectors/date';

function KeyData({ 
  weatherData,
  cityInfos, 
}) {
  let summaryWeather = [];
  if (weatherData !== []) {
    summaryWeather = makeDailySummary(weatherData.list);
  } else {
    summaryWeather = [];
  } 


  return (
      <div className="keyData">
        <h2 className='homeSectionTitle'> Cardet en quelques <b> chiffres clés </b></h2>
        <div className="keyData__container">
            <div className="keyData__container__section">
              <p className="keyData__container__section__text">Population : <b>{cityInfos.numberOfResidents} habitants</b></p>
              <p className="keyData__container__section__text">Superficie : <b>8,29 Km2</b></p>
              <p className="keyData__container__section__text"> Altitude : <b>166m </b></p>
              <p className="keyData__container__section__text"> Nom des Habitants : <b>Cardésiennes, Cardésiens</b> </p>
              <button className='homeSectionButton'> <a className='homeSectionButton__link' href='/territoire/un-peu-dhistoire' >Lire l'histoire de Cardet</a> </button>
            </div>
            <div className="keyData__container__section">
                { !weatherData ? (
                  <p>Chargement des données météo...</p>
                ) : 
                <div className="keyData__container__section__weather">
                  {summaryWeather.map((day) => {
                    const dateStr = day.day;
                    const dateObj = new Date(dateStr);
                    const options = { weekday: 'long' };
                    const count = summaryWeather.indexOf(day);
                    let icon = day.icon;
                    let lastChar = icon.slice(-1); 
                    if (lastChar === 'n') { 
                      icon = icon.slice(0, -1) + 'd'; 
                    }
                    return (
                      <div key={day.day} className={count === 0 ? 'keyData__container__section__weather__day__first' : 'keyData__container__section__weather__day__others'} >
                        {count !== 0 && <p>{convertDayOfWeekToAbbreviation(new Intl.DateTimeFormat('fr-FR', options).format(dateObj))} </p>}
                        {count === 0 && <div><p> CARDET </p> <p>aujourd'hui</p></div>}
                        <img src={"https://openweathermap.org/img/w/" + day.icon + ".png"} alt='icone meteo' className={count === 0 ? 'keyData__container__section__weather__day__first__icon' : 'keyData__container__section__weather__day__others__icon'} />
                        <p>
                          {day.weather.description}
                          {day.minTemp}°C/{day.maxTemp}°C 
                        </p>
                        
                      </div>
                    );
                  }).slice(0, 4)} {/* slice to display only 4 days */}
                </div>
                }
                
            </div>
        </div>

      </div>
  );
}

export default KeyData;