import React, {useContext} from 'react';
import {UserContext} from "../../context/UserContext"
import Navbar from './Navbar';
import LoginModal from './LoginModal';
import FileList from './FileList';
import AddFile from './AddFile';
import CityInfo from './CityInfo';

import './style.scss';
import { useEffect } from 'react';
import SideBar from './SideBar';

function Administration({
    getCategories,
    categories,
    getFiles,
    files,
    editFile,
    deleteFile,
    uploadFile,
    isSuccess,
    setIsSuccess,
    isLoading,
    setIsLoading,
    cityInfos, 
    updateCityInfos
}) {

useEffect(() => {
    getCategories();
    getFiles();
}, [ getCategories, getFiles ]);

const {currentUser} = useContext(UserContext)

const path = window.location.pathname;

  return (
    <div className='administration'>
      <Navbar />
      <LoginModal />
      { currentUser && <SideBar /> }

      {(() => {
        switch (path) {
          case '/administration':
            return (
              currentUser && (
                <div className="content">
                  < FileList
                    files={files}
                    editFile={editFile} 
                    categories={categories}
                    deleteFile={deleteFile}
                  />
                </div>
              )
            );
          case '/administration/ajout-fichier': 
            return (
              currentUser && (
                <div className="content">
                  <AddFile 
                    uploadFile={uploadFile} 
                    categories={categories}
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}  
                  />
                </div>
              )
            );
          case '/administration/infos-mairie':
            return (
              currentUser && (
                <div className="content">
                  <CityInfo 
                    cityInfos={cityInfos}
                    updateCityInfos={updateCityInfos}
                  />
                </div>
              )
            );
          default:
            return null;
        }
      })()}
      
    </div>
  );
};

export default Administration;