import {
    SAVE_INFOS
  } from '../actions/city';

  export const initialState = {
    infos: [],
  };

  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {

      case SAVE_INFOS:
        return {
            ...state,
            infos: action.infos,
        };
        
      default:
        return state;
    }
  };

  export default reducer;