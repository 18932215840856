import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/react';

import './style.scss';


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


function AddFile({
    uploadFile,
    categories,
    isSuccess,
    setIsSuccess,
    isLoading,
    setIsLoading,
}) { 
    const [file, setFile] = useState(null);
    const [categoryId, setCategoryId] = useState();
    const [date, setDate] = useState();

    useEffect(() => {
        setIsSuccess(false);
    }, [ setIsSuccess ]);

    const onChange = (file) => {
        setIsSuccess(false);
        const { files } = file.target;
        if (files && files.length !== 0) {
          setFile(files[0]);
        }
    };

    const handleUpload = async () => {
        setIsLoading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('categoryId', categoryId);
        formData.append('date', date);
        try {
           await uploadFile(formData);
        } catch (error) {
            console.log(error);
        } 
    };

    return (
        <div className='addFile'>       
            <form onSubmit={e => e.preventDefault()} className='addFile__form'>
                <input type="file" onChange={onChange} className='addFile__form__file'/>
                <input type="date" onChange={e => setDate(e.target.value)} className='addFile__form__date'/>
                <select
                    name="categoryId"
                    id="categoryId"
                    onChange={e => setCategoryId(e.target.value)}
                    className='addFile__form__category'
                >
                    <option value="">Choisir une catégorie</option>
                    {categories && categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>
                {isLoading ? ( 
                    <div className="loader-wrapper addFile__form__loader">
                        <BeatLoader css={override} size={15} color={'#123abc'} loading={isLoading} />
                    </div>
                ) : (
                    <button onClick={handleUpload} className='addFile__form__button'>Ajouter</button>
                )
                }
    
                
                {isSuccess && <div className='addFile__form__success'>Le fichier a bien été ajouté</div>}
                
            </form>
        </div>
    );
};

export default AddFile;
