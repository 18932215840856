import { createContext, useState, useEffect } from "react";

import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail
} from "firebase/auth"

import {auth} from "../firebase-config"

export const UserContext = createContext()

export function UserContextProvider(props) {

  const login = (email, pwd) => signInWithEmailAndPassword(auth, email, pwd)
  const resetPassword = (email) => sendPasswordResetEmail(auth, email)

  const [currentUser, setCurrentUser] = useState();
  const [loadingData, setLoadingData] = useState(true);
  
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser)
      setLoadingData(false)
    })

    return unsubscribe;

  }, [])


  // modal
  const [modalState, setModalState] = useState({
    signInModal: false
  })

  const toggleModals = modal => {
    if(modal === "signIn") {
      setModalState({
        signInModal: true
      })
    }
    if(modal === "close") {
      setModalState({
        signInModal: false
      })
    }
  }

  return (
    <UserContext.Provider value={{modalState, toggleModals, login, currentUser, resetPassword }}>
      {!loadingData && props.children}
    </UserContext.Provider>
  )
}