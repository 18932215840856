import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from './Navigation';
import BurgerMenu from './BurgerMenu';

import logo from './../../assets/images/logo-site-mairie-2023-primary.png';
import iconFacebook from './../../assets/images/icon-facebook.png';
import './style.scss';

const Header = () => (
  <header className="header">
    <Link to='/' className="header__logo">
        <img src={logo} className="header__logo__image" alt="Logo Mairie de Cardet" />
        <h1 className="header__logo__title">Mairie de Cardet</h1><span className="header__logo__subtitle"> .COM </span>
    </Link>
    <Navigation />
    <BurgerMenu />
    <a href='https://www.facebook.com/profile.php?id=100071301156790' target='_blank' className='header__facebook' rel='noreferrer'> <img className='header__facebook__image' src={iconFacebook} alt='logo Facebook'/> </a>
  </header>
);

export default Header;