import React from 'react'

import "./style.scss"


export default function SideBar() {
  return (
    <nav className="sidebar">
      <div>
          <a href="/administration"
            >
              Liste des fichiers
          </a>
        
          <a href="/administration/ajout-fichier"
            >
              Ajouter un fichier
          </a>

          <a href="/administration/infos-mairie"
            >
              Infos mairie
          </a>
      </div>
    </nav>
  )
}