import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import MyModal from './../MyModal';
import './style.scss';


function SecondsNewsItems({article}) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleReadClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    < div className='container secondsNewsItems' >
      <div className="row">
        <div onClick={handleReadClick} className='secondsNewsItems__content'>
          <img src={article.image} alt={article.title} className='secondsNewsItems__content__image'/>
          <div className="secondsNewsItems__content__right">
            <h2 className='secondsNewsItems__content__right__title'><b>{article.title}</b></h2>
            <p className='secondsNewsItems__content__right__content'>
              {article.description.length > 150 ?
               <span dangerouslySetInnerHTML={{__html: article.description.slice(0, 150)+"..."}}></span>
                :
                <span dangerouslySetInnerHTML={{__html: article.description}}></span>
            }
            </p>
          </div>
        </div>
      </div>
      <MyModal article={article} handleCloseModal={handleCloseModal} isModalOpen={isModalOpen} />
    </div>
  ); 
}

SecondsNewsItems.propTypes = {
};

export default SecondsNewsItems;