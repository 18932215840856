import React from 'react';
//import PropTypes from 'prop-types';
import Card from './../../../components/MyCard';
import './style.scss';


function Agenda({agendaData}) {

  if (agendaData === undefined) {
    return null;
  }

  if (agendaData.length === 0) {
    return null;
  }

  agendaData.sort((a, b) => {
    return new Date(a.start_date) - new Date(b.star_date);
  });

  let isMobile = false;
  if (window.innerWidth < 768) {
    isMobile = true;
  }
  
  return (
      <div className="agenda">
        <h2 className='homeSectionTitle'> <b>Agen</b>da</h2>
        <div className="agenda__content">
            {isMobile ? (
                agendaData.slice(0, 4).map((article) => (
                  <Card article={article} key={article.id} agendaItems/>
                ))
            ) : (
                agendaData.slice(0, 5).map((article) => (
                  <Card article={article} key={article.id} agendaItems/>
                ))
            )}
        </div>
        <button className='homeSectionButton'>
          <a href='/agenda' className='homeSectionButton__link'>Voir tout l'agenda</a>
        </button>      
      </div>
  );
}

Agenda.propTypes = {
};

export default Agenda;