import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import './style.scss';

import placeduchateau from './../../../assets/images/placechateau.JPG.webp';
import bugadieres from './../../../assets/images/bugadieres.JPG.webp';
import chateau from './../../../assets/images/chateau.JPG.webp';
import entreevillage from './../../../assets/images/entreevillage.JPG.webp';
import maseglise from './../../../assets/images/maseglise.JPG.webp';
import rueprincipale from './../../../assets/images/rueprincipale.JPG.webp';
import stade from './../../../assets/images/stade.JPG.webp';
import vuedesmas from './../../../assets/images/vuedesmas.JPG.webp';



const photos = [
  {
    id: 1,
    title: 'Place du château',
    path: placeduchateau,
  },
  {
    id: 2,
    title: 'Mas de l\'Eglise',
    path: maseglise,
  },
  {
    id: 3,
    title: 'Château',
    path: chateau,
  },
  {
    id: 4,
    title: 'Vue des mas du chemin de la Salée',
    path: vuedesmas,
  },
  {
    id: 5,
    title: 'Entrée du village',
    path: entreevillage,
  },
  {
    id: 6,
    title: 'Chemin des Bugadières',
    path: bugadieres,
  },
  {
    id: 7,
    title: 'Rue principale',
    path: rueprincipale,
  },
  {
    id: 8,
    title: 'Stade',
    path: stade,
  },

];



function Photos() {

  const [selectedPhotoId, setSelectedPhotoId] = useState(null);

  const handleClick = (event) => {
    const photoId = event.target.id;
    setSelectedPhotoId(photoId);
  };
  

  return (
      <div className="photos">
        <h2 className='homeSectionTitle'> <b>Et si Cardet</b> était photographié</h2>
        <div className='photos__container'>
          {photos.map((photo) => (
            <div className="photos__container__element" key={photo.id}>
              <img 
                className='photos__container__element__image' 
                src={photo.path} 
                alt={photo.title} 
                onClick={handleClick} 
                id={photo.id} 
              />
              <p className='photos__container__element__title'><b>{photo.title}</b></p>
            </div>
          ))}

          {selectedPhotoId && (
            <div className="full-screen-photo">
              <img
                src={photos.find((photo) => photo.id === Number(selectedPhotoId)).path}
                alt={photos.find((photo) => photo.id === Number(selectedPhotoId)).title}
                onClick={() => setSelectedPhotoId(null)}
                className="full-screen-photo__image"
              />
            </div>
          )}
        </div>
      </div>
    
  );
}

Photos.propTypes = {
};

export default Photos;