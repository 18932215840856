import {
    SAVE_FILES,
    SAVE_CATEGORIES,
    SET_IS_SUCCESS,
    SET_IS_LOADING
} from '../actions/file';

export const initialState = {
    filesByCategory: {},
    categories: [],
    isSuccess: false,
    isLoading: false,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SAVE_FILES:
            let { files } = action;

            const formattedFiles = files.map(file => {
                const date = new Date(file.date);
                const formattedDate = date.toLocaleDateString();
                
                return {
                  ...file,
                  date: formattedDate,
                };
              });
              
            return {
                ...state,
                files : formattedFiles,
                filesByCategory: formattedFiles.reduce((acc, file) => {
                    const { category } = file;
                    if (!acc[category.name]) {
                        acc[category.name] = [];
                    }
                    acc[category.name].push(file);
                    return acc;
                }, {}),
            };
            

        case SAVE_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };

        case SET_IS_SUCCESS:
            return {
                ...state,
                isSuccess: action.isSuccess,
                isLoading: false,
            };

        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };

        default:
            return state;
    }
}

export default reducer;