import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import MyModal from './../MyModal';
import './style.scss';

import {dateConverter} from './../../../selectors/date';

function AgendaItems({article}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleReadClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='agendaItems' >
        <div className='agendaItems__date' onClick={handleReadClick}> 
          <div>{dateConverter(article.start_date)[0]}</div>
            <div className='agendaItems__date__day'>{dateConverter(article.start_date)[1]}</div> 
            <div>{dateConverter(article.start_date)[2]}</div>
        </div>
        <img src={article.image} alt={article.title} className='agendaItems__image' onClick={handleReadClick}/>
        <h2 className='agendaItems__title' onClick={handleReadClick} >{article.title}</h2>
        <p className='agendaItems__content' dangerouslySetInnerHTML={{__html: article.description.slice(0, 60)}}></p>
        <p className='agendaItems__place'>{article.address_label}</p> 
        {/* <button className='agendaItems__button' onClick={handleReadClick}>En savoir +</button> */}
        <MyModal article={article} handleCloseModal={handleCloseModal} isModalOpen={isModalOpen} />
    </div>
  ); 
}

AgendaItems.propTypes = {
};

export default AgendaItems;