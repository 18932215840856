import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from '../src/containers/App';
import Store from './store';
import { PersistGate } from 'redux-persist/integration/react'

import { UserContextProvider } from './context/UserContext';

// eslint-disable-next-line
import 'bootstrap/dist/css/bootstrap.min.css';

const rootReactElement = (
    <Provider store={Store.store}>
      <PersistGate loading={null} persistor={Store.persistor}>
      <Router >
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </Router>
      </PersistGate>
    </Provider>
); 


const target = document.getElementById('root');
ReactDOM.render(rootReactElement, target);