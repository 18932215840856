import React from 'react';
// import PropTypes from 'prop-types';
import menuItems from '../../../data/menu.js';
import './style.scss';
// import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navigation() {

  return (
	<Navbar expand="lg" className='navigation'>
		<Navbar.Collapse>
			<Nav>
				{menuItems.map((item) => (
				<NavDropdown title={item.name} key={item.id} className="navigation__item">
					{item.subItems && item.subItems.map((subItem) => (
						subItem.subsubItems ? 
							(<NavDropdown title={subItem.name} key={subItem.id} className="navigation__subitem" id='navigation__subitem'> 
								{subItem.subsubItems.map((subsubItem) => (
									<NavDropdown.Item href={subsubItem.path} target={subsubItem.target ? subsubItem.target : '_self'} key={subsubItem.id} className="navigation__subsubitem">{subsubItem.target && <i className="bi bi-box-arrow-up-right me-2"></i>}{subsubItem.name}
									</NavDropdown.Item>
								))}
							</NavDropdown>)
						:
							(<Nav.Link href={subItem.path} key={subItem.id} className="navigation__subitem" id='navigation__subitem' target={subItem.target ? subItem.target : '_self'}>{subItem.target && <i className="bi bi-box-arrow-up-right me-2"></i>}{subItem.name}</Nav.Link>)
					))}
				</NavDropdown>
				))}
			</Nav>
		</Navbar.Collapse>
	</Navbar>
		
  );
}

Navigation.prototypes = {
};

Navigation.defaultProps = {
};

export default Navigation;